// src/features/auth/authApiSlice.js
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { apiurl, constant } from "../../utils/constants";

export const userApi = createApi({
  reducerPath: "userApi",
  baseQuery: fetchBaseQuery({
    baseUrl: constant.BASE_URL_PROD,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().userdetail.userdetail?.token;
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ["Profile"],
  endpoints: (builder) => ({
    getAllusers: builder.query({
      query: () => {
        return {
          url: `${apiurl.tonyadmin}${apiurl.getallusers}`,
          method: "GET",
        };
      },
    }),
    getmyprofile: builder.query({
      query: () => {
        return {
          url: `${apiurl.user}${apiurl.getmyprofile}`,
          method: "GET",
        };
      },
      providesTags: ["Profile"],
    }),
    UpdateProfile: builder.mutation({
      query: (data) => ({
        url: `${apiurl.user}${apiurl.editprofile}`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Profile"],
    }),
    changePassword: builder.mutation({
      query: (data) => ({
        url: `${apiurl.auth}${apiurl.changePassword}`,
        method: "POST",
        body: data,
      }),
    }),
  }),
});

export const {
  useGetAllusersQuery,
  useGetmyprofileQuery,
  useUpdateProfileMutation,
  useChangePasswordMutation,
} = userApi;
