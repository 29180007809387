import React, { useState } from "react";
import "@trendmicro/react-sidenav/dist/react-sidenav.css";

import SideNav, {
  Toggle,
  Nav,
  NavItem,
  NavIcon,
  NavText,
} from "@trendmicro/react-sidenav";
import { NavLink, useNavigate } from "react-router-dom/dist";
import { useDispatch, useSelector } from "react-redux";

import {
  setPageName,
  setSearch,
  setSideNav,
} from "../../Redux/Slices/dashBoardSlice";
import {
  DashboardIcon,
  ExclusiveIcon,
  HelpIcon,
  LogOutArrow,
  Logo,
  MusicIcon,
  PodcastIcon,
  SettingIcon,
} from "../../utils/Svg";
import { success } from "../ToastComponent";
import ThemeModal from "../Modals/ThemeModals";
import { setUserDetail } from "../../Redux/Slices/UserSlice";

const SideNavBar = ({ setIsSidebarVisible }) => {
  const [showDelete, setShowDelete] = useState(false);

  const pagename = useSelector((state) => state.pagename.pagename);
  const sidenav = useSelector((state) => state.sidenav.sidenav);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleSelection = (name) => {
    dispatch(setPageName(name));
    if (name === "Podcast") {
      navigate("/podcast");
      dispatch(setSearch(null));
    } else if (name === "Music") {
      navigate("/music");
      dispatch(setSearch(null));
    } else if (name === "Exclusive") {
      navigate("/exclusive");
      dispatch(setSearch(null));
    } else if (name === "Settings") {
      navigate("/setting");
      dispatch(setSearch(null));
    } else if (name === "Help/Support") {
      navigate("/help-support");
      dispatch(setSearch(null));
    } else {
      navigate("/dashboard");
      dispatch(setSearch(null));
    }
  };

  const handleLogout = () => {
    navigate("/");
    setPageName("/dashboard");
    success("Successfully Logged Out");
  };
  const onSubmitModal = (data) => {
    setShowDelete(false);
    dispatch(setUserDetail(null)); // Dispatching the setUserEmail action
    success("Successfully Logged Out");
  };
  return (
    <div className={`${sidenav ? "sidebar-custom" : "custom-back"}`}>
      <SideNav expanded={sidenav}>
        <div className="toggle-custom">
          <SideNav.Toggle
            onClick={() => {
              dispatch(setSideNav(!sidenav));
            }}
          ></SideNav.Toggle>
          <div
            className={`logo mb-3 cursor ${sidenav ? "" : "d-none"}`}
            onClick={() => navigate("/")}
          >
            <Logo />
          </div>
        </div>
        <div className="line-podcast m-0"></div>
        <SideNav.Nav defaultSelected={pagename.toLowerCase()}>
          <NavItem
            onClick={() => handleSelection("Dashboard")}
            eventKey="dashboard"
          >
            <NavIcon>
              <DashboardIcon />
            </NavIcon>
            <NavText>Dashboard</NavText>
          </NavItem>
          <NavItem
            onClick={() => handleSelection("Podcast")}
            eventKey="podcast"
          >
            <NavIcon>
              <PodcastIcon />
            </NavIcon>
            <NavText>Podcast</NavText>
          </NavItem>
          <NavItem onClick={() => handleSelection("Music")} eventKey="music">
            <NavIcon>
              <MusicIcon />
            </NavIcon>
            <NavText>Music</NavText>
          </NavItem>
          <NavItem
            onClick={() => handleSelection("Exclusive")}
            eventKey="exclusive"
          >
            <NavIcon>
              <ExclusiveIcon />
            </NavIcon>
            <NavText>Exclusive</NavText>
          </NavItem>
          <NavItem
            onClick={() => handleSelection("Settings")}
            eventKey="settings"
          >
            <NavIcon>
              <SettingIcon />
            </NavIcon>
            <NavText>Settings</NavText>
          </NavItem>
          <NavItem
            onClick={() => handleSelection("Help/Support")}
            eventKey="help/support"
          >
            <NavIcon>
              <HelpIcon />
            </NavIcon>
            <NavText>Help/Support</NavText>
          </NavItem>
          <div className="line-podcast top-logout"></div>
          <div
            className={`logout mt-5 mb-5 cursor ${sidenav ? "" : "d-none"}`}
            onClick={() => setShowDelete(true)}
          >
            <span>Logout</span>
            <LogOutArrow />
          </div>
        </SideNav.Nav>
      </SideNav>
      <ThemeModal
        setState={setShowDelete}
        initialState={showDelete}
        onSubmitModal={onSubmitModal}
        cancelText={"Cancel"}
        deletetext={"Confirm"}
        heading={"Sure? You want to Log Out?"}
        Text={"Click Confirm to log out"}
      />
    </div>
  );
};

export default SideNavBar;
