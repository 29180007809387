// src/features/auth/authApiSlice.js
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { apiurl, constant } from "../../utils/constants";

export const uiCoverImage = createApi({
  reducerPath: "uiCoverImage",
  baseQuery: fetchBaseQuery({
    baseUrl: constant.BASE_URL_PROD,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().userdetail.userdetail?.token;
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getUiCoverImage: builder.query({
      query: () => {
        return {
          url: `${apiurl.getuiimage}`,
          method: "GET",
        };
      },
    }),
  }),
});

export const { useGetUiCoverImageQuery } = uiCoverImage;
