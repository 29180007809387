import React, { Suspense } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Assets from "../../utils/Assets";
import LazyImage from "../../utils/Test";
import { useGetUiCoverImageQuery } from "../../Redux/Slices/uiimage";
import { constant } from "../../utils/constants";
import { useParams } from "react-router-dom";

const AuthLayout = ({ children }) => {
  const { isLoading, isFetching, data, refetch } = useGetUiCoverImageQuery();

  console.log(data?.data?.rows[0], "data auth");
  const path = window.location.pathname;
  console.log(path);
  return (
    <>
      <div className="Authlayout">
        <Row>
          <Col sm={8} lg={7} xl={7} className="d-none d-lg-block">
            <div className="left-banner mb-4">
              <Suspense fallback={<div>Loading...</div>}>
                <LazyImage
                  className={"mt-4"}
                  style={{ maxHeight: "800px" }}
                  src={
                    path === "/sign-in"
                      ? `${constant?.BASE_URL_IMAGE}${data?.data?.rows[0]?.login_image}`
                      : path === "/sign-up"
                      ? `${constant?.BASE_URL_IMAGE}${data?.data?.rows[0]?.signup_image}`
                      : Assets.BannerAuth
                  }
                  alt="Banner Auth"
                />
              </Suspense>
              {/* <img className="" src={Assets.BannerAuth} alt="" /> */}
            </div>
          </Col>

          <Col
            sm={12}
            lg={5}
            xl={{ span: 4, offset: 1 }}
            className="d-flex text-align-center align-items-center auth-body"
          >
            {children}
          </Col>
        </Row>
      </div>
    </>
  );
};
export default AuthLayout;
