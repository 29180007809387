import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Button, Form } from "react-bootstrap";
import { Logo } from "../../utils/Svg";
import { NavLink, useLocation } from "react-router-dom/dist";

const TopNavBanner = () => {
  const location = useLocation();
  const { pathname } = location;

  const [expanded, setExpanded] = useState(false);

  const handleNavToggle = () => {
    setExpanded(!expanded);
  };
  return (
    <>
      <div className="top-nav-bar">
        <Navbar bg="" expand="lg" variant="dark">
          <Container>
            <Navbar.Brand href="/">
              <Logo />
            </Navbar.Brand>
            <Navbar.Toggle
              aria-controls="responsive-navbar-nav"
              onClick={handleNavToggle}
            />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav
                className="ms-auto"
                defaultActiveKey="/"
                onClick={handleNavToggle}
              >
                <Nav.Item className={"p-3"}>
                  <Nav.Link href="/" onClick={handleNavToggle}>
                    Home
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className={"p-3"}>
                  <Nav.Link href="#feature" onClick={handleNavToggle}>
                    Features
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className={"p-3"}>
                  <Nav.Link href="#faqs" onClick={handleNavToggle}>
                    FAQs
                  </Nav.Link>
                </Nav.Item>
                <NavLink to="/sign-up" onClick={handleNavToggle}>
                  <Button>Register</Button>
                </NavLink>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
    </>
  );
};

export default TopNavBanner;
