// src/features/auth/authApiSlice.js
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { apiurl, constant } from "../../utils/constants";

export const supportApi = createApi({
  reducerPath: "supportApi",
  baseQuery: fetchBaseQuery({ baseUrl: constant.BASE_URL_PROD }),
  endpoints: (builder) => ({
    createSupport: builder.mutation({
      query: (credentials) => ({
        url: `${apiurl.support}`,
        method: "POST",
        body: credentials,
      }),
    }),
  }),
});

export const { useCreateSupportMutation } = supportApi;
