export const VALIDATIONS = {
  NAME_MIN: 3,
  NAME_MAX: 32,
  FIRST_NAME_MIN: 3,
  FIRST_NAME_MAX: 32,
  LAST_NAME_MIN: 3,
  LAST_NAME_MAX: 32,
  FULL_NAME_MIN: 3,
  FULL_NAME_MAX: 60,
  EMAIL: 32,
  PASSWORD_MIN: 8,
  PASSWORD_MAX: 15,
  PHONE_MIN: 11,
  PHONE_MAX: 15,
  ADDRESS_MAX: 200,
  DESCRIPTION_MAX: 255,
  BIO_MAX: 255,
  VERIFICATION_CODE: 4,
  CARD_NUMBER: 16,
  CVV_MIN: 3,
  CVV_MAX: 3,
  VERIFICATION_CODE_MIN: 4,
  VERIFICATION_CODE_MAX: 4,
  SUBJECT_MIN: 3,
  SUBJECT_MAX: 60,
  MESSAGE_MIN: 3,
  MESSAGE_MAX: 60,
  CARD_NUMBER_MIN: 16,
  CARD_NUMBER_MAX: 16,
  MOTHER_TOUNGE_MIN: 3,
  MOTHER_TOUNGE_MAX: 32,
  CAST_MIN: 3,
  CAST_MAX: 32,
  TASK_MIN: 3,
  TASK_MAX: 120,
};

export const VALIDATIONS_TEXT = {
  REQUIRED: "Required",
  USER_NAME_REQUIRED: "Username is required",
  USER_NAME_FORMAT: "Username may only contain letters and numbers",

  FIRST_NAME_REQUIRED: "First Name is required",
  FIRST_NAME_MIN: "Min length is " + VALIDATIONS.FIRST_NAME_MIN,
  FIRST_NAME_MAX: "Max length is " + VALIDATIONS.FIRST_NAME_MAX,

  FULL_NAME_REQUIRED: "Full Name is required",
  FULL_NAME_MIN: "Min length is " + VALIDATIONS.FULL_NAME_MIN,
  FULL_NAME_MAX: "Max length is " + VALIDATIONS.FULL_NAME_MAX,

  LAST_NAME_REQUIRED: "Last Name is required",
  LAST_NAME_MIN: "Min length is " + VALIDATIONS.LAST_NAME_MIN,
  LAST_NAME_MAX: "Max length is " + VALIDATIONS.LAST_NAME_MAX,

  NAME_REQUIRED: "Name is required",
  NAME_MIN: "Min length is " + VALIDATIONS.NAME_MIN,
  NAME_MAX: "Max length is " + VALIDATIONS.NAME_MAX,

  SUBJECT_REQUIRED: "Subject is required",
  SUBJECT_MIN: "Min length is " + VALIDATIONS.SUBJECT_MIN,
  SUBJECT_MAX: "Max length is " + VALIDATIONS.SUBJECT_MAX,

  MESSAGE_REQUIRED: "Message is required",
  MESSAGE_MIN: "Min length is " + VALIDATIONS.MESSAGE_MIN,
  MESSAGE_MAX: "Max length is " + VALIDATIONS.MESSAGE_MAX,

  EMAIL_REQUIRED: "Email is required",
  EMAIL_MAX: "Max length is " + VALIDATIONS.EMAIL,
  EMAIL_FORMAT: "Invalid email format",

  CONFIRM_NEW_PASSWORD_REQUIRED: "Confirm new password is required",
  CURRENT_PASSWORD_REQUIRED: "Current password is required",
  NEW_PASSWORD_REQUIRED: "New password is required",
  CONFIRM_PASSWORD_REQUIRED: "Confirm password is required",

  PASSWORD_REQUIRED: "Password is required",
  PASSWORD_MIN: "Min length is " + VALIDATIONS.PASSWORD_MIN,
  PASSWORD_MAX: "Max length is " + VALIDATIONS.PASSWORD_MAX,
  PASSWORD_FORMAT:
    "Password must be at-least 8 characters long with one upper case.",

  PHONE_REQUIRED: "Contact number is required",
  PHONE_MIN: "Min length is " + VALIDATIONS.PHONE_MIN,
  PHONE_MAX: "Max length is " + VALIDATIONS.PHONE_MAX,

  ADDRESS_REQUIRED: "Address is required",
  ADDRESS_MAX: "Max length is " + VALIDATIONS.ADDRESS_MAX,

  DESCRIPTION_REQUIRED: "Description is required",
  DESCRIPTION_MAX: "Max length is " + VALIDATIONS.DESCRIPTION_MAX,

  VERIFICATION_CODE_REQUIRED: "Otp code is required",
  VERIFICATION_CODE_MIN: "Min Length is " + VALIDATIONS.VERIFICATION_CODE_MIN,
  VERIFICATION_CODE_MAX: "Max Length is " + VALIDATIONS.VERIFICATION_CODE_MAX,

  CARD_NUMBER_REQUIRED: "Card number is required",
  CARD_NUMBER_MIN: "Min length is " + VALIDATIONS.CARD_NUMBER,
  CARD_NUMBER_MAX: "Max length is " + VALIDATIONS.CARD_NUMBER,

  CVV_REQUIRED: "Cvv is requried",
  CVV_MIN: "Min length is " + VALIDATIONS.CVV_MIN,
  CVV_MAX: "Max length is " + VALIDATIONS.CVV_MAX,

  VERIFICATION_CODE: "Length is " + VALIDATIONS.VERIFICATION_CODE,
  INVALID_FORMAT: "Invalid format",

  TENURE_REQUIRED: "Tenure is required",
  PAYMENT_MODE_REQUIRED: "Payment mode is required",
  EXPIRY_REQUIRED: "Expiry date is requried",

  FILE_REQUIRED: "File is required",

  AGE_REQUIRED: "Age is required",
  GENDER_REQUIRED: "Gender is required",

  MOTHER_TOUNGE_REQUIRED: "Mother tounge is required",
  MOTHER_TOUNGE_MIN: "Min length is " + VALIDATIONS.MOTHER_TOUNGE_MIN,
  MOTHER_TOUNGE_MAX: "Max length is " + VALIDATIONS.MOTHER_TOUNGE_MAX,

  RELIGION_REQUIRED: "Religion is required",
  MARTIAL_STATUS_REQUIRED: "Martial status is required",
  COUNTRY_REQUIRED: "Country is required",
  SPOKEN_LANGUAGE_REQUIRED: "Spoken language is required",
  PHYSICAL_STATUS_REQUIRED: "Physcal status is required",
  BODY_TYPE_REQUIRED: "Body type is required",
  MEDICAL_REQUIRED: "Medical is required",
  HEIGHT_REQUIRED: "Height is required",
  COMPLEXION_REQUIRED: "Complexion is required",
  DRINKING_HEBITS_REQUIRED: "Drinking hebits is required",
  EATING_HEBITS_REQUIRED: "Eating hebits is required",
  SMOKING_HEBITS_REQUIRED: "Smoking hebits is required",
  VEGETARIAN_REQUIRED: "Vegetarian is required",
  IMAGE_REQUIRED: "Image is required",
  CURRENCY_REQUIRED: "Currency is required",
  BUDGET_REQUIRED: "Budget is required",
  PROGRAM_REQUIRED: "Program is required",
  FAMILY_COUNT_REQUIRED: "Family count is required",
  GUEST_REQUIRED: "Guest is required",
  VENDOR_REQUIRED: "Vendor is required",

  TASK_MIN: "Min length is " + VALIDATIONS.TASK_MIN,
  TASK_MAX: "Max length is " + VALIDATIONS.TASK_MAX,
  TASK_REQUIRED: "Task is required",

  CAST_REQUIRED: "Cast is required",
  CAST_MIN: "Min length is " + VALIDATIONS.CAST_MIN,
  CAST_MAX: "Max length is " + VALIDATIONS.CAST_MAX,

  BIO_MAX: "Max length is " + VALIDATIONS.BIO_MAX,
  AGE: "User is under 18 years of age.",
};

export const SPECIAL_CHARACTER_ARRAY = [
  "~",
  "`",
  "!",
  "@",
  "#",
  "$",
  "%",
  "^",
  "&",
  "*",
  "(",
  ")",
  "-",
  "_",
  "+",
  "=",
  "{",
  "}",
  "[",
  "]",
  ";",
  ":",
  "'",
  '"',
  "\\",
  "|",
  "<",
  ",",
  ">",
  ".",
  "?",
  "/",
];

export const SPECIAL_CHARACTER_ARRAY_FOR_ADDRESS = [
  "~",
  "`",
  "!",
  "@",
  "#",
  "$",
  "%",
  "^",
  "&",
  "*",
  "(",
  ")",
  "_",
  "+",
  "=",
  "{",
  "}",
  "[",
  "]",
  ";",
  ":",
  "'",
  '"',
  "\\",
  "|",
  "<",
  ">",
  ".",
  "?",
  "/",
];

export const CHARACTER_ARRAY_FOR_NUMBER_INPUT = ["e", "E", "-", "+", "."];
