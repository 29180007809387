import React from "react";
import TopNavBanner from "../components/Static/TopNavBAr";
import TopBanner from "../components/Static/TopBanner";
import SliderComponent from "../components/Home/SliderComponent";
import Features from "../components/Home/Features";
import FaqsHome from "../components/Home/Faqs";
import HomeFooter from "../components/Home/HomeFooter";


const Home = () => {
    return (
        <>
            <div className="home">
                <div className="home-bannar">
                    <TopNavBanner />
                    <TopBanner />
                </div>
                <div className="slider-comp mt-5 mb-5">
                    <SliderComponent />
                </div>
                <Features />
                <FaqsHome />
                <HomeFooter />
            </div>
        </>
    )
}

export default Home