import React from "react";
import { Button, Col, Form, Row } from 'react-bootstrap';
import Assets from "../utils/Assets";
import { GoBackIcon, Tick, WhiteTick } from "../utils/Svg";
import { useNavigate } from "react-router-dom";

const Plans = () => {
    const navigate = useNavigate()
    return (
        <>
            <div className="plans mt-5 mb-5">
                <div className="top-plan-heading d-flex">
                    <div className="back-optoin cursor" onClick={() => navigate('/setting')}>
                        <GoBackIcon />
                    </div>
                    <div>
                        <div className="mb-3">
                            <h2 className="mb-3">The Right Plan for Your Entertainment</h2>
                            <p className="mb-3">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                        </div>
                        <div className="list-item mt-5">
                            <ul>
                                <li>Bill Monthly</li>
                                <li>
                                    <Form.Check // prettier-ignore
                                        type="switch"
                                        id="custom-switch"
                                    />
                                </li>
                                <li>Bill Annualy</li>
                            </ul>

                        </div>
                    </div>
                </div>
                <Row className="mt-4">
                    <Col xs={12} md={12} lg={4} className="col-box">
                        <div className="box-plan">
                            <h2>Basic</h2>
                            <ul class="fa-ul">
                                <li><i><Tick /></i>Upload Video up to 720p Resolution</li>
                                <li><i><Tick /></i>Attachment & Post Scheduling</li>
                                <li><i><Tick /></i>Attachment & Post Scheduling</li>
                                <li><i><Tick /></i>Upload Video up to 720p Resolution</li>
                                <li><i><Tick /></i>Attachment & Post Scheduling</li>
                            </ul>
                            <p className="duration"><span>Free</span>/month</p>
                            <div className="btn mt-4">
                                <Button>Choose</Button>
                            </div>
                        </div>
                    </Col>
                    <Col xs={12} md={12} lg={4} className="col-box ">
                        <div className="box-plan">
                            <h2>Basic</h2>
                            <ul class="fa-ul">
                                <li><i><Tick /></i>Upload Video up to 720p Resolution</li>
                                <li><i><Tick /></i>Attachment & Post Scheduling</li>
                                <li><i><Tick /></i>Attachment & Post Scheduling</li>
                                <li><i><Tick /></i>Upload Video up to 720p Resolution</li>
                                <li><i><Tick /></i>Attachment & Post Scheduling</li>
                            </ul>
                            <p className="duration">$50/month</p>
                            <div className="btn mt-4">
                                <Button>Upgrade Plan</Button>
                            </div>
                        </div>
                    </Col>
                    <Col xs={12} md={12} lg={4} className="col-box">
                        <div className="box-plan buy-now">
                            <div className="pro d-flex">
                                <h2>Pro</h2>
                                <span className="ms-auto">
                                    Save $40
                                </span>
                            </div>
                            <ul class="fa-ul">
                                <li><i><WhiteTick /></i>Upload Video up to 720p Resolution</li>
                                <li><i><WhiteTick /></i>Attachment & Post Scheduling</li>
                                <li><i><WhiteTick /></i>Attachment & Post Scheduling</li>
                                <li><i><WhiteTick /></i>Upload Video up to 720p Resolution</li>
                                <li><i><WhiteTick /></i>Attachment & Post Scheduling</li>
                            </ul>
                            <p className="duration"><span>$100</span>/month</p>
                            <div className="btn mt-4">
                                <Button>Buy Now</Button>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    )
}
export default Plans