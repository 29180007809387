import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // Defaults to localStorage for web
import {
  locationpathReducer,
  pagenameReducer,
  searchReducer,
  sidenavReducer,
} from "./Slices/dashBoardSlice";
import persistConfig from "./persistConfig";
import { userdetailReducer, useremailReducer } from "./Slices/UserSlice";
import { authApi } from "./Slices/Auth";
import { videoApi } from "./Slices/Videos";
import { userApi } from "./Slices/users";
import { pageApi } from "./Slices/pages";
import { likeApi } from "./Slices/like";
import { supportApi } from "./Slices/support";
import { commentApi } from "./Slices/comments";
import { uiCoverImage } from "./Slices/uiimage";

const rootReducer = combineReducers({
  pagename: pagenameReducer,
  sidenav: sidenavReducer,
  useremail: useremailReducer,
  userdetail: userdetailReducer,
  search: searchReducer,
  locationpath: locationpathReducer,
  [authApi.reducerPath]: authApi.reducer,
  [videoApi.reducerPath]: videoApi.reducer,
  [userApi.reducerPath]: userApi.reducer,
  [likeApi.reducerPath]: likeApi.reducer,
  [pageApi.reducerPath]: pageApi.reducer,
  [supportApi.reducerPath]: supportApi.reducer,
  [commentApi.reducerPath]: commentApi.reducer,
  [uiCoverImage.reducerPath]: uiCoverImage.reducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export default persistedReducer;
