import { DashboardIcon } from "./Svg";

const Assets = {
  Logo: require("../assets/images/Logo.png"),
  BannerAuth: require("../assets/images/Auth/AuthBanner.png"),
  BannerAddImage: require("../assets/images/TopBanner.png"),
  FirstFeature: require("../assets/images/Home/First-feature.png"),
  SecondFeature: require("../assets/images/Home/Second-feature.png"),
  ThirdFeature: require("../assets/images/Home/Third-feature.png"),
  FourthFeature: require("../assets/images/Home/Fourth-feature.png"),
  profileDummy: require("../assets/images/Ellipse 1.png"),
  card1: require("../assets/images/card/1.png"),
  card2: require("../assets/images/card/2.png"),
  card3: require("../assets/images/card/3.png"),
  card4: require("../assets/images/card/4.png"),
  ProfileSetting: require("../assets/images/setting/profile.png"),
  uploadIcon: require("../assets/images/setting/upload.png"),
  help1: require("../assets/images/helpandsupport/1.png"),
  help2: require("../assets/images/helpandsupport/2.png"),
  help3: require("../assets/images/helpandsupport/3.png"),
  forwardArrow: require("../assets/images/helpandsupport/Arrow.png"),
  NotiIcon: require("../assets/images/notiiIcon.png"),
  Tick: require("../assets/images/Icons/entypo_check.png"),
  DashboardSlider2: require("../assets/images/Dashboard/Bannar.png"),
  comm1: require("../assets/images/comments/Ellipse 25 (1).png"),
  comm2: require("../assets/images/comments/Ellipse 25 (2).png"),
  comm3: require("../assets/images/comments/Ellipse 25 (3).png"),
  comm4: require("../assets/images/comments/Ellipse 25.png"),
  reel: require("../assets/videos/reel.mp4"),
  videoIcon: require("../assets/images/Icons/video.png"),
  Next: require("../assets/images/Icons/Next.png"),
  slidernext: require("../assets/images/Icons/btn - Next.png"),
  sliderPrev: require("../assets/images/Icons/btn - Previous.png"),
  volumeIcon: require("../assets/images/Icons/Volume.png"),
  // Videos
  Video: require("../assets/images/video.mp4"),
  secondVideo: require("../assets/videos/React.mp4"),
  // Placeholder
  placeholderimg: require("../assets/images/Dashboard/placeholder.jpg"),
};
export default Assets;
