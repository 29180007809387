import React, { useState, useRef, useEffect } from "react";
import { Modal } from "react-bootstrap";
import Assets from "../../utils/Assets";
import { constant } from "../../utils/constants";
import {
  BackVideo,
  ForwardVideo,
  FullScreen,
  Videoplay,
} from "../../utils/Svg";
import { MdOutlineMotionPhotosPause } from "react-icons/md";
import { VscMute } from "react-icons/vsc";

const VideoModal = ({ videoUrl, size, initialState, setIsOpen }) => {
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [progress, setProgress] = useState(0);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [showvolume, setShowVolume] = useState(false); // Default volume is 100%
  const [volume, setVolume] = useState(1); // Default volume is 100%
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);

  useEffect(() => {
    const videoElement = videoRef.current;
    console.log(videoElement, "videoElement.duration");
    if (videoElement) {
      const handleTimeUpdate = () => {
        setCurrentTime(videoElement.currentTime);
        setProgress((videoElement.currentTime / videoElement.duration) * 100);
      };

      const handleLoadedMetadata = () => {
        setDuration(videoElement.duration);
      };

      videoElement.addEventListener("timeupdate", handleTimeUpdate);
      videoElement.addEventListener("loadedmetadata", handleLoadedMetadata);

      return () => {
        videoElement.removeEventListener("timeupdate", handleTimeUpdate);
        videoElement.removeEventListener(
          "loadedmetadata",
          handleLoadedMetadata
        );
      };
    }
  }, [initialState]);

  const togglePlayPause = () => {
    const videoElement = videoRef.current;
    if (videoElement) {
      if (videoElement.paused) {
        videoElement.play();
        setIsPlaying(true);
      } else {
        videoElement.pause();
        setIsPlaying(false);
      }
    }
  };

  const handleProgressClick = (e) => {
    const progressBar = e.target;
    const newTime =
      (e.nativeEvent.offsetX / progressBar.offsetWidth) *
      videoRef.current.duration;
    videoRef.current.currentTime = newTime;
  };

  const backVideo = () => {
    const videoElement = videoRef.current;
    if (videoElement) {
      videoElement.currentTime = Math.min(
        videoElement.currentTime - 15,
        videoElement.duration
      );
    }
  };
  const forwardVideo = () => {
    const videoElement = videoRef.current;
    if (videoElement) {
      videoElement.currentTime = Math.min(
        videoElement.currentTime + 15,
        videoElement.duration
      );
    }
  };
  const toggleFullScreen = () => {
    const videoElement = videoRef.current;

    if (!document.fullscreenElement) {
      videoElement.requestFullscreen().then(() => setIsFullScreen(true));
    } else {
      document.exitFullscreen().then(() => setIsFullScreen(false));
    }
  };
  const handleVolumeChange = (e) => {
    console.log(e);
    const newVolume = e.target.value;
    setVolume(newVolume);
    videoRef.current.volume = newVolume;
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
  };

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsPlaying(false);
    setIsOpen(false);
  };

  return (
    <>
      <Modal
        className="video-theme-popup"
        show={initialState}
        onHide={() => {
          setIsOpen(false);
        }}
        size={size}
        centered
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Body>
          <div className="video-modal">
            {initialState && (
              <div className="modal-overlay cursor">
                <div className="modal-content">
                  <button className="close-btn" onClick={closeModal}>
                    X
                  </button>
                  <div className="video-container">
                    <video
                      ref={videoRef}
                      className="video-fluid z-depth-1 d-block"
                      loop
                      onClick={togglePlayPause}
                    >
                      <source
                        src={`${constant?.BASE_URL_IMAGE}${videoUrl}`}
                        type="video/mp4"
                      />
                    </video>
                    <div className="video-controls">
                      <div
                        className="progress-bar mb-3"
                        onClick={handleProgressClick}
                      >
                        <div
                          className="progress"
                          style={{ width: `${progress}%` }}
                        ></div>
                      </div>
                      <div className="d-flex controls-bottom">
                        <div className="d-flex">
                          <div className="back-video" onClick={backVideo}>
                            <BackVideo />
                          </div>
                          <div className="video-button-video">
                            <button
                              className="play-pause-button"
                              onClick={togglePlayPause}
                            >
                              {isPlaying ? (
                                <MdOutlineMotionPhotosPause
                                  color="white"
                                  size={36}
                                />
                              ) : (
                                <Videoplay />
                              )}
                            </button>
                          </div>
                          <div className="Forward-video" onClick={forwardVideo}>
                            <ForwardVideo />
                          </div>
                          <div className="time-display">
                            {formatTime(currentTime)} / {formatTime(duration)}
                          </div>
                        </div>
                        <div className="d-flex right-side-option">
                          <div className="d-flex">
                            <div
                              className="cursor volume-icon"
                              onClick={() => setShowVolume(!showvolume)}
                            >
                              {volume == 0 ? (
                                <VscMute
                                  className="volume-image"
                                  color="white"
                                  size={32}
                                />
                              ) : (
                                <img
                                  className={"volume-image"}
                                  src={Assets.volumeIcon}
                                />
                              )}
                            </div>
                            <div
                              className={`volume-control ${
                                showvolume ? "d-block" : "d-none"
                              }`}
                            >
                              <input
                                type="range"
                                min="0"
                                max="1"
                                step="0.01"
                                value={volume}
                                className={"volume-range"}
                                onChange={handleVolumeChange}
                              />
                            </div>
                          </div>
                          <div>
                            <button
                              className="full-screen-btn"
                              onClick={toggleFullScreen}
                            >
                              {isFullScreen ? <FullScreen /> : <FullScreen />}
                            </button>
                          </div>
                          <div>{/* <ForwardVideo /> */}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default VideoModal;
