import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import Assets from "../utils/Assets";
import { UploadIcon } from "../utils/Svg";
import { VALIDATIONS, VALIDATIONS_TEXT } from "../utils/Validations";
import CustomInput from "../components/CustomInput";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import ThemeModal from "../components/Modals/ThemeModals";
import { errorToast, success } from "../components/ToastComponent";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { constant } from "../utils/constants";
import {
  useChangePasswordMutation,
  useGetmyprofileQuery,
  useUpdateProfileMutation,
} from "../Redux/Slices/users";
import { setUserDetail } from "../Redux/Slices/UserSlice";

const SettingPage = () => {
  const { data, error, isLoading } = useGetmyprofileQuery();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [cancelPlan, setCancelPlan] = useState(false);
  const [profileImg, setProfileImage] = useState(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const fileInputRef = useRef(null);

  const {
    handleSubmit: handleSubmitProfile,
    control: controlProfile,
    reset: profileReset,
    formState: { errors: profileErrors },
  } = useForm({ mode: "onChange" });

  // For Password Change Form
  const {
    handleSubmit: handleSubmitPassword,
    watch,
    control: controlPassword,
    formState: { errors: passwordErrors },
  } = useForm({ mode: "onChange" });
  const new_password = watch("newPassword");

  useEffect(() => {
    if (data) {
      profileReset({
        firstName: data?.data?.firstName,
        lastName: data?.data?.lastName,
        email: data?.data?.email,
      });
    }
  }, [data, profileReset]);

  useEffect(() => {
    // Clean up the URL.createObjectURL when the component unmounts or image changes
    return () => {
      if (profileImg) {
        URL.revokeObjectURL(profileImg);
      }
    };
  }, [profileImg]);

  const fetchMyProfile = useGetmyprofileQuery();
  const [
    UpdateProfile,

    {
      isLoading: isUpdateProfileloading,
      isSuccess: isUpdateProfileSuccess,
      isError: isUpdateProfileIsError,
      error: isUpdateProfileError,
    },
  ] = useUpdateProfileMutation();
  const [
    UpdatePassword,
    {
      isLoading: isUpdatepasswordloading,
      isSuccess: isUpdatepasswordSuccess,
      isError: isUpdatepasswordIsError,
      error: isUpdatepasswordError,
    },
  ] = useChangePasswordMutation();

  const handleUploadClick = () => {
    // Trigger click on file input element
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (e) => {
    // Handle file selection (e.target.files[0])
    const selectedFile = e.target.files[0];
    setProfileImage(selectedFile);
    // Perform additional actions here, e.g., upload the file
  };
  const onSubmitData = async (data) => {
    let formData = new FormData();
    formData.append("firstName", data?.firstName);
    formData.append("lastName", data?.lastName);
    if (!profileImg) {
      delete data["image"];
    } else {
      formData.append("image", profileImg);
    }
    try {
      const result = await UpdateProfile(formData).unwrap();
      success(result.message);
      fetchMyProfile.refetch();
    } catch (e) {
      errorToast(e.data?.message);
    }
  };

  const onSubmitPass = async (data) => {
    try {
      const result = await UpdatePassword(data).unwrap();
      success(result.message);
      dispatch(setUserDetail(null));
    } catch (e) {
      errorToast(e.data?.message);
    }
  };

  const onSubmitModal = (data) => {
    setCancelPlan(false);
    success("Subscription has been canceled");
  };

  return (
    <>
      <div className="setting-page container mt-5">
        <div className="plan-billing mb-4">
          <Row className="p-4">
            <Col xs={12} sm={12} md={12} lg={6}>
              <div className="heading">Plan & Billing</div>
            </Col>
            <Col xs={12} sm={12} md={12} lg={6}>
              <Row>
                <Col xs={12} sm={12} md={12} lg={6}>
                  <Button
                    className="cancel-sub"
                    onClick={() => setCancelPlan(true)}
                  >
                    Cancel Subscription
                  </Button>
                </Col>
                <Col xs={12} sm={12} md={12} lg={6}>
                  <Button
                    onClick={() => navigate("/plans")}
                    className="change-plan"
                  >
                    Change Plan
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
          <div className="line-podcast"></div>
          <Row className="p-4">
            <Col xs={12} sm={12} md={12} lg={5}>
              <div className="currnet-plan">
                <p className="heading">Current Plan</p>
                <p className="text">Monthly Plan</p>
                <p className="detail">
                  $50/Month<span>(Active)</span>
                </p>
              </div>
            </Col>
            <Col xs={12} sm={12} md={1} className="d-none d-lg-block">
              <div className="vertical-line"></div>
            </Col>
            <div className="line-podcast line-podcast-custom-width mt-3 mb-3 d-block d-lg-none"></div>

            <Col xs={12} sm={12} md={12} lg={5}>
              <div className="plan-renewal">
                <p className="heading">Plan Renewal</p>
                <p className="text">Renew at</p>
                <p className="detail">1st Dec, 2024</p>
              </div>
            </Col>
          </Row>
        </div>

        <div className="plan-billing edit-profile mb-4 p-4">
          <Form onSubmit={handleSubmitProfile(onSubmitData)}>
            <Row>
              <Col xs={12} md={3}>
                <div
                  className="upload-image cursor"
                  onClick={handleUploadClick}
                >
                  <img
                    src={
                      profileImg
                        ? URL.createObjectURL(profileImg)
                        : data?.data?.image
                        ? `${constant.BASE_URL_IMAGE}uploads/${data?.data?.image}`
                        : Assets.placeholderimg
                    }
                  />
                  <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    onChange={handleFileChange}
                  />
                  <div className="upload-svg">
                    <UploadIcon />
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={6} className="mt-3">
                <p className="account-detail">Account Details</p>
              </Col>
              <Col xs={12} md={6}>
                <div className="btn d-flex justify-content-end ms-auto">
                  <Button type="submit" disabled={isUpdateProfileloading}>
                    Edit Profile
                  </Button>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={6}>
                <div className="mt-3">
                  <Controller
                    control={controlProfile}
                    name="firstName"
                    rules={{
                      required: {
                        value: true,
                        message: VALIDATIONS_TEXT.NAME_REQUIRED,
                      },
                      maxLength: {
                        value: VALIDATIONS.NAME_MAX,
                        message: VALIDATIONS_TEXT.EMAIL,
                      },
                    }}
                    render={({ field }) => (
                      <CustomInput
                        {...field}
                        label="First Name"
                        placeholder="First Name"
                        className="name"
                        type="text"
                        required={true}
                        maxLength={VALIDATIONS.NAME_MAX}
                        value={field.value}
                        onChange={field.onChange}
                      />
                    )}
                  />
                  {profileErrors.firstName && (
                    <small className="text-start d-block text-danger">
                      {profileErrors.firstName.message}
                    </small>
                  )}
                </div>
              </Col>
              <Col xs={12} md={6}>
                <div className="mt-3">
                  <Controller
                    control={controlProfile}
                    name="lastName"
                    rules={{
                      required: {
                        value: true,
                        message: VALIDATIONS_TEXT.NAME_REQUIRED,
                      },
                      maxLength: {
                        value: VALIDATIONS.NAME_MAX,
                        message: VALIDATIONS_TEXT.EMAIL,
                      },
                    }}
                    render={({ field }) => (
                      <CustomInput
                        {...field}
                        label="Last Name"
                        placeholder="Last Name"
                        className="name"
                        type="text"
                        required={true}
                        maxLength={VALIDATIONS.NAME_MAX}
                        value={field.value}
                        onChange={field.onChange}
                      />
                    )}
                  />
                  {profileErrors.lastName && (
                    <small className="text-start d-block text-danger">
                      {profileErrors.lastName.message}
                    </small>
                  )}
                </div>
              </Col>
              <Col xs={12} md={12}>
                <div className="mt-3">
                  <Controller
                    control={controlProfile}
                    name="email"
                    rules={{
                      required: {
                        value: true,
                        message: VALIDATIONS_TEXT.EMAIL_REQUIRED,
                      },
                      maxLength: {
                        value: VALIDATIONS.EMAIL,
                        message: VALIDATIONS_TEXT.EMAIL,
                      },
                      pattern: {
                        value: /\S+@\S+\.\S+/,
                        message: VALIDATIONS_TEXT.EMAIL_FORMAT,
                      },
                    }}
                    render={({ field }) => (
                      <CustomInput
                        {...field}
                        label="Email Address"
                        placeholder="Email Address"
                        type="email"
                        className="name"
                        required={true}
                        maxLength={VALIDATIONS.EMAIL}
                        value={field.value}
                        onChange={field.onChange}
                      />
                    )}
                  />
                  {profileErrors.email && (
                    <small className="text-start d-block text-danger">
                      {profileErrors.email.message}
                    </small>
                  )}
                </div>
              </Col>
            </Row>
          </Form>

          <Form onSubmit={handleSubmitPassword(onSubmitPass)} className="mt-3">
            <Row>
              <Col xs={12} md={6} className="mt-3">
                <p className="account-detail">Change Password</p>
              </Col>
              <Col xs={12} md={6}>
                <div className="btn d-flex justify-content-end ms-auto">
                  <Button type="submit" disabled={isUpdatepasswordloading}>
                    Save
                  </Button>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={6}>
                <div className="mt-3">
                  <Controller
                    control={controlPassword}
                    name="password"
                    rules={{
                      required: {
                        value: true,
                        message: VALIDATIONS_TEXT.PASSWORD_REQUIRED,
                      },
                      minLength: {
                        value: VALIDATIONS.PASSWORD_MIN,
                        message: VALIDATIONS_TEXT.PASSWORD_MIN,
                      },
                      maxLength: {
                        value: VALIDATIONS.PASSWORD_MAX,
                        message: VALIDATIONS_TEXT.PASSWORD_MAX,
                      },
                    }}
                    render={({ field }) => (
                      <div className="password-input">
                        <CustomInput
                          {...field}
                          label="Current Password"
                          placeholder="Current Password"
                          className="name"
                          type={showPassword ? "text" : "password"}
                          required={true}
                          minLength={VALIDATIONS.PASSWORD_MIN}
                          maxLength={VALIDATIONS.PASSWORD_MAX}
                          value={field.value}
                          onChange={field.onChange}
                        />
                      </div>
                    )}
                  />
                  {passwordErrors.password && (
                    <small className="text-start d-block text-danger">
                      {passwordErrors.password.message}
                    </small>
                  )}
                </div>
              </Col>
              <Col xs={12} md={6}>
                <div className="mt-3">
                  <Controller
                    control={controlPassword}
                    name="newPassword"
                    rules={{
                      required: {
                        value: true,
                        message: VALIDATIONS_TEXT.PASSWORD_REQUIRED,
                      },
                      minLength: {
                        value: VALIDATIONS.PASSWORD_MIN,
                        message: VALIDATIONS_TEXT.PASSWORD_MIN,
                      },
                      maxLength: {
                        value: VALIDATIONS.PASSWORD_MAX,
                        message: VALIDATIONS_TEXT.PASSWORD_MAX,
                      },
                    }}
                    render={({ field }) => (
                      <div className="password-input">
                        <CustomInput
                          {...field}
                          label="New Password"
                          placeholder="New Password"
                          className="name"
                          type={showConfirmPassword ? "text" : "password"}
                          required={true}
                          minLength={VALIDATIONS.PASSWORD_MIN}
                          maxLength={VALIDATIONS.PASSWORD_MAX}
                          value={field.value}
                          onChange={field.onChange}
                        />
                      </div>
                    )}
                  />
                  {passwordErrors.newPassword && (
                    <small className="text-start d-block text-danger">
                      {passwordErrors.newPassword.message}
                    </small>
                  )}
                </div>
              </Col>
              <Col xs={12}>
                <div className="mt-3">
                  <Controller
                    control={controlPassword}
                    name="confirmPassword"
                    rules={{
                      required: {
                        value: true,
                        message: VALIDATIONS_TEXT.CONFIRM_PASSWORD_REQUIRED,
                      },
                      minLength: {
                        value: VALIDATIONS.PASSWORD_MIN,
                        message: VALIDATIONS_TEXT.PASSWORD_MIN,
                      },
                      maxLength: {
                        value: VALIDATIONS.PASSWORD_MAX,
                        message: VALIDATIONS_TEXT.PASSWORD_MAX,
                      },
                      validate: (value) =>
                        value === new_password || "The Passwords do not match",
                    }}
                    render={({ field }) => (
                      <div className="password-input">
                        <CustomInput
                          {...field}
                          label="Confirm Password"
                          placeholder="Confirm Password"
                          className="name"
                          type={showConfirmPassword ? "text" : "password"}
                          required={true}
                          minLength={VALIDATIONS.PASSWORD_MIN}
                          maxLength={VALIDATIONS.PASSWORD_MAX}
                          value={field.value}
                          onChange={field.onChange}
                        />
                      </div>
                    )}
                  />
                  {passwordErrors.confirmPassword && (
                    <small className="text-start d-block text-danger">
                      {passwordErrors.confirmPassword.message}
                    </small>
                  )}
                </div>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
      {cancelPlan && (
        <ThemeModal
          btnText={"Cancel Subscription"}
          cancelText={"Not Now"}
          heading={`Do You want to cancel The Subscription? `}
          Text={
            "Once you cancelled the subscription you will no longer to watch premium Songs/raps."
          }
          initialState={cancelPlan}
          setState={setCancelPlan}
          onSubmitModal={onSubmitModal}
          size={"md"}
        />
      )}
    </>
  );
};

export default SettingPage;
