import React, { useEffect, useState } from "react";
import CarouselFadeComponent from "../components/Dashboard/BannarSlider";
import DetailCard from "../components/DetailCard";
import Assets from "../utils/Assets";
import ModalVideo from "react-modal-video";
import ReelsComponent from "./Reels";
import { useGetVideoContentBycatQuery } from "../Redux/Slices/Videos";
import { useSelector } from "react-redux";

const DashBoardPage = () => {
  const [currentOption, setCurrentOption] = useState("PODCASTING");
  const [firstRenderData, setFirstRenderData] = useState(4);
  const search = useSelector((state) => state.search.search);

  const selectOtipn = [
    { id: "PODCASTING", name: "Podcasting" },
    { id: "VLOGGING", name: "Vlogging" },
    { id: "MUSICVIDEOS", name: "Music Videos" },
    { id: "BITESIZEDCLIPS", name: "Bite Sized Clips" },
    { id: "REALITYSHOW", name: "Reality Show" },
  ];

  const { data, error, isLoading, isFetching } = useGetVideoContentBycatQuery({
    cat: currentOption,
    params: {
      page: 1,
      limit: 20,
      sortKey: "averageRating",
      ...(search && { search: search }),
    },
  });

  // useEffect(() => {
  //   setIsLoading(true); // Immediately set isLoading to true
  //   const timer = setTimeout(() => {
  //     setIsLoading(false); // Set isLoading to false after 3 seconds
  //   }, 3000);

  //   return () => clearTimeout(timer); // Cleanup function to clear timeout if component unmounts or currentOption changes again
  // }, [currentOption]);

  const defaultData = data?.data?.data?.slice(0, firstRenderData);

  // video
  let currentSelectedCatName = selectOtipn?.find(
    (item) => item.id === currentOption
  );
  return (
    <>
      <CarouselFadeComponent />
      <div className="type-details container">
        <div className="select-type">
          <ul>
            {selectOtipn?.map((dt) => (
              <li
                key={dt?.id}
                onClick={() => setCurrentOption(dt?.id)}
                className={`cursor ${currentOption === dt?.id ? "active" : ""}`}
              >
                {dt?.name}
              </li>
            ))}
          </ul>
        </div>
        {currentOption === "BITESIZEDCLIPS" ? (
          <>
            <div className="top-heading">
              <h1 className="heading">{currentSelectedCatName?.name}</h1>
            </div>
            <ReelsComponent
              data={data?.data?.data}
              isLoading={isLoading}
              isFetching={isFetching}
            />
          </>
        ) : (
          <>
            <div className="top-heading">
              <h1 className="heading">{currentSelectedCatName?.name}</h1>
              {defaultData?.length > 5 ? (
                firstRenderData === data?.length ? (
                  <p
                    className="cursor see-all"
                    onClick={() => setFirstRenderData(4)}
                  >
                    Show less
                  </p>
                ) : (
                  <p
                    className="cursor see-all"
                    onClick={() => setFirstRenderData(data.length)}
                  >
                    See All
                  </p>
                )
              ) : (
                ""
              )}
            </div>
            <DetailCard
              data={defaultData}
              isLoading={isLoading}
              isFetching={isFetching}
            />
          </>
        )}
      </div>
    </>
  );
};
export default DashBoardPage;
