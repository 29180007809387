import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { BsSearch } from "react-icons/bs"; // Import search icon from Bootstrap Icons
import { CrossNoti, FilterIcon, IsRead, NotiIcon } from "../../utils/Svg";
import Assets from "../../utils/Assets";
import { useLocation } from "react-router-dom";
import Notifications from "react-notifications-menu";
import { FaRegBell } from "react-icons/fa6";
import Dropdown from "react-bootstrap/Dropdown";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useGetmyprofileQuery } from "../../Redux/Slices/users";
import { constant } from "../../utils/constants";
import { setSearch } from "../../Redux/Slices/dashBoardSlice";

const HeaderBar = ({ pagename }) => {
  const location = useLocation();
  const { data: myProfile, error, isLoading } = useGetmyprofileQuery();
  let userName = myProfile?.data?.firstName + " " + myProfile?.data?.lastName;

  const [menuOpen, setMenuOpen] = useState(false);
  const dispatch = useDispatch();

  const handleClick = (data) => {
    setTimeout(() => {
      dispatch(setSearch(data?.target?.value)); // or any other value or action you want to perform
    }, 1000); // Set the timeout delay in milliseconds (e.g., 1000ms = 1 second)
  };
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };
  const data = [
    {
      message: (
        <div className="data">
          <div className="data-info">
            <div className="tittle">Subscription Renewal</div>
            <div className="mesg">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </div>
          </div>
          <div className="isread">
            <IsRead />
          </div>
        </div>
      ),
    },
    {
      message: (
        <div className="data">
          <div className="data-info">
            <div className="tittle">New Artist Album Update</div>
            <div className="mesg">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </div>
          </div>
          <div className="isread">
            <IsRead />
          </div>
        </div>
      ),
    },
    {
      message: (
        <div className="data">
          <div className="data-info">
            <div className="tittle">Announcements</div>
            <div className="mesg">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </div>
          </div>
          <div className="isread">
            <IsRead />
          </div>
        </div>
      ),
    },
    {
      message: (
        <div className="data">
          <div className="data-info">
            <div className="tittle">Upcoming Events</div>
            <div className="mesg">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </div>
          </div>
          <div className="isread">
            <IsRead />
          </div>
        </div>
      ),
    },
    {
      message: (
        <div className="data">
          <div className="data-info">
            <div className="tittle">Subscription Renewal</div>
            <div className="mesg">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </div>
          </div>
          <div className="isread">
            <IsRead />
          </div>
        </div>
      ),
    },
    {
      message: (
        <div className="data">
          <div className="data-info">
            <div className="tittle">New Artist Album Update</div>
            <div className="mesg">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </div>
          </div>
          <div className="isread">
            <IsRead />
          </div>
        </div>
      ),
    },
    {
      message: (
        <div className="data">
          <div className="data-info">
            <div className="tittle">Announcements</div>
            <div className="mesg">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </div>
          </div>
          <div className="isread">
            <IsRead />
          </div>
        </div>
      ),
    },
    {
      message: (
        <div className="data">
          <div className="data-info">
            <div className="tittle">Upcoming Events</div>
            <div className="mesg">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </div>
          </div>
          <div className="isread">
            <IsRead />
          </div>
        </div>
      ),
    },
    {
      message: (
        <div className="data">
          <div className="data-info">
            <div className="tittle">Subscription Renewal</div>
            <div className="mesg">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </div>
          </div>
          <div className="isread">
            <IsRead />
          </div>
        </div>
      ),
    },
    {
      message: (
        <div className="data">
          <div className="data-info">
            <div className="tittle">New Artist Album Update</div>
            <div className="mesg">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </div>
          </div>
          <div className="isread">
            <IsRead />
          </div>
        </div>
      ),
    },
    {
      message: (
        <div className="data">
          <div className="data-info">
            <div className="tittle">Announcements</div>
            <div className="mesg">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </div>
          </div>
          <div className="isread">
            <IsRead />
          </div>
        </div>
      ),
    },
    {
      message: (
        <div className="data">
          <div className="data-info">
            <div className="tittle">Upcoming Events</div>
            <div className="mesg">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </div>
          </div>
          <div className="isread">
            <IsRead />
          </div>
        </div>
      ),
    },
  ];
  return (
    <Navbar fixed="" expand="xl" className="bg-body-tertiary">
      <Container fluid>
        <Navbar.Brand>{pagename}</Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav
            className="me-auto my-2 my-lg-0"
            style={{ maxHeight: "100px" }}
            navbarScroll
          ></Nav>
          {location?.pathname === "/setting" ||
          location?.pathname === "/help-support" ? (
            ""
          ) : (
            <div className="search-input">
              <Form.Control
                type="search"
                placeholder={`Search across our channel`}
                // className="me-2"
                aria-label="Search"
                className="form-input"
                onChange={handleClick}
              />
              <BsSearch className="search-icon" />
              {/* <FilterIcon /> */}
              {/* <span className='filter-text'>Filter</span> */}
              {/* <span className="filter-text">
                <NavDropdown title="Filter" id="navbarScrollingDropdown">
                  <NavDropdown.Item href="#action3">Hip Hop</NavDropdown.Item>
                  <NavDropdown.Item href="#action4">Rap Songs</NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item href="#action5">
                    Rock music
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#action5">Funky Rap</NavDropdown.Item>
                </NavDropdown>
              </span> */}
            </div>
          )}
          <div className="noti-icon">
            {/* <NotiIcon /> */}
            <div>
              <Notifications
                style={{ color: "red" }}
                data={data}
                // fetchData
                // prop to fetch data on pagination
                icon={Assets.NotiIcon} // Use the FaRegBell icon component here
                header={{
                  title: (
                    <h4 className="notification__heading">Notifications</h4>
                  ),
                  option: {
                    // text: <p className="mark__read" onClick={() => setMenuOpen(false)}><CrossNoti /></p>,
                    // onClick: () => console.log("Clicked")
                  },
                }}
                markAsRead={(data) => {
                  console.log(data);
                }}
              />
            </div>
          </div>
          <div className="profile-detail me-2">
            <img
              className="image-fluid"
              src={
                myProfile?.data?.image === null
                  ? Assets.placeholderimg
                  : `${constant.BASE_URL_IMAGE}uploads/${myProfile?.data?.image}`
              }
            />
            <p>{userName}</p>
          </div>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default HeaderBar;
