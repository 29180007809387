import React from "react"

import Assets from "../../utils/Assets"
const HomeFooter = () => {
    return (
        <>
            <div className="home-footer container mb-4">
                <div className="logo"><img src={Assets.Logo} /></div>
                <div className="copy-right-data ms-auto">
                    <span>Copy Right @ Tony Willrich Network</span>
                    <span>Terms & Condition</span>
                    <span>Help & Support</span>
                </div>
            </div>
        </>
    )
}
export default HomeFooter