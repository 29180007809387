import { configureStore } from "@reduxjs/toolkit";
import { persistStore } from "redux-persist";
import persistedReducer from ".";
import { authApi } from "./Slices/Auth";
import { videoApi } from "./Slices/Videos";
import { userApi } from "./Slices/users";
import { likeApi } from "./Slices/like";
import { pageApi } from "./Slices/pages";
import { supportApi } from "./Slices/support";
import { commentApi } from "./Slices/comments";
import { uiCoverImage } from "./Slices/uiimage";

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore these action types
        ignoredActions: ["persist/PERSIST", "persist/REHYDRATE"],
        // Ignore these field paths in all actions
        ignoredActionPaths: ["meta.arg", "payload"],
        // Ignore these paths in the state
        ignoredPaths: ["register", "rehydrate"],
      },
    })
      .concat(authApi.middleware)
      .concat(videoApi.middleware)
      .concat(userApi.middleware)
      .concat(likeApi.middleware)
      .concat(pageApi.middleware)
      .concat(supportApi.middleware)
      .concat(commentApi.middleware)
      .concat(uiCoverImage.middleware),
});

export const persistor = persistStore(store);
