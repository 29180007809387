import React, { useState } from "react";
import { Carousel, Button } from "react-bootstrap";
import Assets from "../../utils/Assets";
import { NextIconSlider, PrevIconSlider } from "../../utils/Svg";
import DashboardSlider2 from "../../assets/images/Dashboard/Bannar.png";
import { useNavigate } from "react-router-dom";
const CarouselFadeComponent = () => {
  const [currentSlide, setCurrentSlide] = useState(0); // State to track current slide index
  const navigate = useNavigate();
  const slides = [DashboardSlider2, DashboardSlider2, DashboardSlider2];

  const handlePrevSlide = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide === 0 ? slides.length - 1 : prevSlide - 1
    );
  };

  const handleNextSlide = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide === slides.length - 1 ? 0 : prevSlide + 1
    );
  };

  return (
    <div className="custom-carousel">
      <div className="image-carousel">
        <img
          src={slides[currentSlide]}
          className="w-100 image-fluid"
          alt={`Slide ${currentSlide + 1}`}
        />
        <div className="icons">
          <img
            src={Assets.sliderPrev}
            alt="Previous Slide"
            className="arrow"
            onClick={handlePrevSlide}
          />
          <div className="dots-container">
            {slides.map((slide, index) => (
              <span
                key={index}
                className={`dot ${index === currentSlide ? "active" : ""}`}
                onClick={() => setCurrentSlide(index)}
              ></span>
            ))}
          </div>
          <img
            src={Assets.slidernext}
            alt="Next Slide"
            className="arrow"
            onClick={handleNextSlide}
          />
        </div>

        <div className="btn-watch-now">
          <Button className="btn" onClick={() => navigate("/watch-detail")}>
            Watch Now
          </Button>{" "}
          {/* Example button */}
        </div>
      </div>
    </div>
  );
};

export default CarouselFadeComponent;
