import React from "react";
import { NavLink, useNavigate } from "react-router-dom/dist";
import Assets from "../../utils/Assets";
import { Row, Col, Button, Container } from 'react-bootstrap'
const Features = () => {
    const navigate = useNavigate()
    return (
        <>
            <Container>
                <div className="features" id="feature">
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={6}>
                            <p className="heading mb-4">Features</p>
                            <h1>Reveal Our Platform's Exclusive Creative Offerings</h1>
                            <p className="lorem-feature mt-2">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
                            <NavLink to={'/sign-up'}>
                                <Button className="mt-5">
                                    Sign Up Now
                                </Button>
                            </NavLink>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={6}>
                            <div className="feature-container">
                                <div className="feature-block">
                                    <img src={Assets.FirstFeature} />
                                    <div className="line"></div>
                                    <p className="heading-in-card mt-2">Profile</p>
                                    <div className="content">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                    </div>
                                </div>
                                <div className="feature-block right-feature">
                                    <img src={Assets.SecondFeature} />
                                    <div className="line"></div>
                                    <p className="heading-in-card mt-2">Profile</p>
                                    <div className="content">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                    </div>
                                </div>
                            </div>
                            <div className="feature-container">
                                <div className="feature-block mt-4">
                                    <img src={Assets.ThirdFeature} />
                                    <div className="line"></div>
                                    <p className="heading-in-card mt-2">Profile</p>
                                    <div className="content">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                    </div>
                                </div>
                                <div className="feature-block mt-4 right-feature">
                                    <img src={Assets.FourthFeature} />
                                    <div className="line"></div>
                                    <p className="heading-in-card mt-2">Profile</p>
                                    <div className="content">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div >
            </Container >
        </>
    )
}

export default Features