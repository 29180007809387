import React, { useState } from "react";
import OtpInput from "react-otp-input";
import Assets from "../utils/Assets";
import { Controller, useForm } from "react-hook-form";
import { Button, Form } from "react-bootstrap";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { NavLink, useNavigate } from "react-router-dom";

import { VALIDATIONS, VALIDATIONS_TEXT } from "../utils/Validations";
import CustomInput from "./CustomInput";
import { GoBackIcon } from "../utils/Svg";
import { errorToast, success } from "./ToastComponent";
import { useSelector } from "react-redux";
import {
  useChangePasswordMutation,
  useResendotpMutation,
  useVerifyOTPMutation,
} from "../Redux/Slices/Auth";

const VerifyOtp = () => {
  const navigate = useNavigate();
  const [otp, setOtp] = useState("");

  const {
    handleSubmit,
    register,
    control,
    reset,
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({ mode: "onChange" });
  const password = watch("password");
  const useremail = useSelector((state) => state.useremail.useremail);
  const locationpath = useSelector((state) => state.locationpath.locationpath);
  const [verifyotp, { isLoading, isSuccess, isError, error }] =
    useVerifyOTPMutation();
  const [resendotp] = useResendotpMutation();

  const handleChangePassSubmit = async (data) => {
    let payload = { ...data, email: useremail };
    try {
      const result = await verifyotp(payload).unwrap();
      success(result.message);
      if (locationpath === "/sign-up") {
        navigate("/sign-in");
      } else {
        navigate("/change-password");
      }
    } catch (e) {
      errorToast(e.data?.message);
    }
  };
  const handleResendOTP = async () => {
    let payload = { email: useremail };
    try {
      const result = await resendotp(payload).unwrap();
      success(result.message);
    } catch (e) {
      errorToast(e.data?.message);
    }
  };
  const handleBack = () => {
    if (locationpath === "/sign-up") {
      navigate("/sign-up");
    } else {
      navigate("/forgot-password");
    }
  };

  return (
    <>
      <div className="signin-component">
        <div className="logo mb-3">
          <img src={Assets.Logo} />
        </div>
        <div onClick={handleBack} className="cursor go-back d-flex mt-5 mb-2">
          <GoBackIcon />
        </div>

        <div className="mt-1 mb-1 change-pass-tag-line w-100">
          <h1>Verify OTP </h1>
          <h5>Please enter your new OTP Code</h5>
        </div>
        <div className="sign-in-form otp-form mt-2">
          <Form onSubmit={handleSubmit(handleChangePassSubmit)}>
            <Controller
              control={control}
              name="otp"
              rules={{
                required: {
                  value: true,
                  message: VALIDATIONS_TEXT.Required,
                },
                validate: (value) =>
                  value.length === 4 || VALIDATIONS_TEXT.OtpLength,
              }}
              render={({ field }) => (
                <OtpInput
                  value={otp}
                  className="name otp-form-input"
                  onChange={(value) => {
                    setOtp(value);
                    field.onChange(value);
                  }}
                  numInputs={6}
                  renderSeparator={<span>-</span>}
                  renderInput={(props) => <input {...props} />}
                />
              )}
            />
            {errors.otp && (
              <small className="text-start d-block text-danger">
                {errors.otp.message}
              </small>
            )}
            <div
              className="mt-1 mb-1 ms-auto w-100 d-flex justify-content-start cursor"
              onClick={handleResendOTP}
            >
              Resend OTP
            </div>
            <div className="btn-sumit">
              <Button
                className="btn-solid btn-blue mt-30"
                type="submit"
                disabled={isLoading}
              >
                Continue
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};
export default VerifyOtp;
