import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";

const ProtectedRoute = () => {
  const userdetail = useSelector((state) => state.userdetail.userdetail);
  const navigate = useNavigate();
  useEffect(() => {
    if (!userdetail && !userdetail?.token) {
      navigate("/sign-in");
    }
  }, [userdetail, navigate]);
  return (
    <>{userdetail && userdetail?.token ? <Outlet /> : navigate("/sign-in")}</>
  );
};

export default ProtectedRoute;
