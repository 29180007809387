import React from "react";
import { Row, Col, Button, Form } from "react-bootstrap";
import { VALIDATIONS, VALIDATIONS_TEXT } from "../utils/Validations";
import { Controller, useForm } from "react-hook-form";
import CustomInput from "../components/CustomInput";
import Assets from "../utils/Assets";
import { ForwardArrow } from "../utils/Svg";
import { useGetPageBySlugQuery } from "../Redux/Slices/pages";
import { GrowLoader } from "../components/Loader";
import { useCreateSupportMutation } from "../Redux/Slices/support";
import { errorToast, success } from "../components/ToastComponent";

const HelpAndSupport = () => {
  const {
    handleSubmit,
    register,
    control,
    reset,
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({ mode: "onChange" });
  const { data, error, isLoading } = useGetPageBySlugQuery({
    params: {
      slug: "terms_and_conditions",
    },
  });

  // Support Post API
  const [
    craeteSupport,
    {
      isLoading: isSupportLoading,
      isSuccess: isSupportSuccess,
      isError: isSupportError,
      error: supportError,
    },
  ] = useCreateSupportMutation();

  const onSubmitData = async (data) => {
    try {
      const result = await craeteSupport(data).unwrap();
      success("Thank you for your interest! We will get back to you soon.");
      reset();
    } catch (e) {
      errorToast(e.data?.message);
    }
  };
  return (
    <>
      <div className="help-and-support container">
        <Row className="row-head mt-4">
          <Col xs={12} md={12}>
            <div className="heading">How Can We Help You?</div>
          </Col>
          <Col xs={12} md={12}>
            <Form onSubmit={handleSubmit(onSubmitData)}>
              <div className="form-email mt-3">
                <div className="email-input">
                  <Controller
                    control={control}
                    name="email"
                    rules={{
                      required: {
                        value: true,
                        message: VALIDATIONS_TEXT.EMAIL_REQUIRED,
                      },
                      maxLength: {
                        value: VALIDATIONS.EMAIL,
                        message: VALIDATIONS_TEXT.EMAIL,
                      },
                      pattern: {
                        value: /\S+@\S+\.\S+/,
                        message: VALIDATIONS_TEXT.EMAIL_FORMAT,
                      },
                    }}
                    render={({ field }) => (
                      <CustomInput
                        {...field}
                        label="Email Address"
                        placeholder="Email Address"
                        type="email"
                        className="name"
                        required={true}
                        maxLength={VALIDATIONS.EMAIL}
                        value={field.value}
                        onChange={field.onChange}
                      />
                    )}
                  />
                  {errors.email && (
                    <small className="text-start d-block text-danger">
                      {errors.email.message}
                    </small>
                  )}
                </div>
                <Button type="submit" disabled={isSupportLoading}>
                  Submit
                </Button>
              </div>
            </Form>
          </Col>
        </Row>
        <div className="box-row mt-4">
          <div className="box">
            <div className="box-img">
              <img src={Assets.help1} />
              <ForwardArrow />
            </div>
            <p className="mt-3">Account Settings</p>
          </div>
          <div className="box">
            <div className="box-img">
              <img src={Assets.help2} />
              <ForwardArrow />
            </div>
            <p className="mt-3">Forgot Password</p>
          </div>
          <div className="box">
            <div className="box-img">
              <img src={Assets.help3} />
              <ForwardArrow />
            </div>
            <p className="mt-3">Other Issue</p>
          </div>
        </div>
        <div className="paragraph mt-4">
          {isLoading ? (
            <GrowLoader />
          ) : (
            data?.data?.length > 0 && (
              <p
                dangerouslySetInnerHTML={{
                  __html: data?.data[0]?.content.replace(/\n/g, "<br />"),
                }}
              ></p>
            )
          )}
          <p className="mt-4">Terms & Condition</p>
        </div>
      </div>
    </>
  );
};

export default HelpAndSupport;
