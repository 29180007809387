import Spinner from "react-bootstrap/Spinner";

const LoaderCustom = ({ className }) => {
  return (
    <Spinner
      animation="border"
      variant="light"
      size="md"
      className={className}
    />
  );
};
function GrowLoader({ className }) {
  return (
    <Spinner animation="grow" variant="light" size="md" className={className} />
  );
}

export { LoaderCustom, GrowLoader };
