import React, { useState } from "react";
import Assets from "../utils/Assets";
import { Controller, useForm } from "react-hook-form";
import { Button, Form } from "react-bootstrap";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { NavLink, useLocation, useNavigate } from "react-router-dom/dist";

import { VALIDATIONS, VALIDATIONS_TEXT } from "../utils/Validations";
import CustomInput from "./CustomInput";
import { GoBackIcon } from "../utils/Svg";
import { errorToast, success } from "./ToastComponent";
import { useForgotPasswordMutation } from "../Redux/Slices/Auth";
import { setLocationPath } from "../Redux/Slices/dashBoardSlice";
import { useDispatch } from "react-redux";
import { setUserEmail } from "../Redux/Slices/UserSlice";

const ForgotPassword = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const Location = useLocation();
  const [forgotpas, { isLoading, isSuccess, isError, error }] =
    useForgotPasswordMutation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm({ mode: "onChange" });
  const password = watch("password");

  const handleForgotPassSubmit = async (data) => {
    try {
      const result = await forgotpas(data).unwrap();
      success(result.message);
      dispatch(setUserEmail(result?.data?.email)); // Dispatching the setUserEmail action
      dispatch(setLocationPath(Location?.pathname));
      navigate("/verify-Otp");
    } catch (e) {
      errorToast(e.data?.message);
    }
  };
  const handleBack = () => {
    navigate("/sign-in");
  };

  return (
    <>
      <div className="signin-component">
        <div className="logo mb-4">
          <img src={Assets.Logo} />
        </div>
        <div onClick={handleBack} className="cursor go-back mt-5 mb-2">
          <GoBackIcon />
        </div>

        <div className="mt-1 mb-1 forgot-pass-tag-line">
          <h1>Forgot Password</h1>
          <h5>
            In order to reset your password you need to enter your registered
            email address.
          </h5>
        </div>

        <div className="sign-in-form">
          <Form onSubmit={handleSubmit(handleForgotPassSubmit)}>
            <div className="mt-3">
              <Controller
                control={control}
                name="email"
                rules={{
                  required: {
                    value: true,
                    message: VALIDATIONS_TEXT.EMAIL_REQUIRED,
                  },
                  maxLength: {
                    value: VALIDATIONS.EMAIL,
                    message: VALIDATIONS_TEXT.EMAIL,
                  },
                  pattern: {
                    value: /\S+@\S+\.\S+/,

                    message: VALIDATIONS_TEXT.EMAIL_FORMAT,
                  },
                }}
                render={({ field }) => (
                  <CustomInput
                    {...field}
                    label="Email Address"
                    placeholder="Email Address"
                    type="email"
                    className="name"
                    required={true}
                    maxLength={VALIDATIONS.EMAIL}
                    value={field.value}
                    onChange={field.onChange}
                  />
                )}
              />
              {errors.email && (
                <small className="text-start d-block text-danger">
                  {errors.email.message}
                </small>
              )}
            </div>
            <div className="btn-sumit">
              <Button
                className="btn-solid btn-blue mt-30"
                type="submit"
                disabled={isLoading}
              >
                Continue
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};
export default ForgotPassword;
