import { createSlice } from "@reduxjs/toolkit";

// Define initial state
const initialState = {
  useremail: null,
};
const initialUserDetailState = {
  userdetail: null, // Assuming user details can include more information
};
export const useremailSlice = createSlice({
  name: "useremail",
  initialState,
  reducers: {
    setUserEmail: (state, action) => {
      state.useremail = action.payload;
    },
  },
});

export const userdetailSlice = createSlice({
  name: "userdetail",
  initialState: initialUserDetailState,
  reducers: {
    setUserDetail: (state, action) => {
      state.userdetail = action.payload;
    },
  },
});

export const { setUserEmail } = useremailSlice.actions,
  { setUserDetail } = userdetailSlice.actions;
export const useremailReducer = useremailSlice.reducer;
export const userdetailReducer = userdetailSlice.reducer;
