import React, { Suspense, useState } from "react";
import Assets from "../utils/Assets";
import { Button, Col, Row } from "react-bootstrap";
import { Rating } from "react-simple-star-rating";
import { Premium } from "../utils/Svg";
import VideoModal from "./Modals/VideoModal";
import { useNavigate } from "react-router-dom";
import { constant } from "../utils/constants";
import { GrowLoader } from "./Loader";
import LazyImage from "../utils/Test";
const DetailCard = ({ data, isLoading, isFetching }) => {
  console.log(data, "dataaaaa");

  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [video, setVideo] = useState(null);
  // const data = Array.from({ length: 20 }, (_, index) => index + 1);

  const [rating, setRating] = useState(0);

  // Catch Rating value
  const handleRating = (rate: number) => {
    setRating(rate);

    // other logic
  };

  const onPointerEnter = () => console.log("Enter");
  const onPointerLeave = () => console.log("Leave");
  const onPointerMove = (value: number, index: number) =>
    console.log(value, index);
  const handleButtonCLick = (e) => {
    console.log(e, "aaa");
    if (e?.is_premium) {
      navigate("/watch-detail");
    } else {
      setVideo(e.shortvideo);
      setIsOpen(true);
    }
  };

  return (
    <>
      <div className="custom-detail-card mt-4 mb-5">
        {isLoading || isFetching ? (
          <GrowLoader />
        ) : data?.length > 0 ? (
          data?.map((item, index) => (
            <div className="card-data" key={index}>
              <div className="image-container">
                <Suspense fallback={<div></div>}>
                  <LazyImage
                    src={`${constant.BASE_URL_IMAGE}${item?.thumbnailImage}`}
                    alt="Banner Auth"
                  />
                </Suspense>

                {/* <img
                  src={`${constant.BASE_URL_IMAGE}${item?.thumbnailImage}`}
                  className="image-fluid"
                  alt={`Card ${index + 1}`}
                /> */}
                <div className="overlay-content">
                  <div className="text-head w-100">
                    {item?.videodetail?.title}
                  </div>
                  <div className="stars">
                    <Rating
                      onClick={handleRating}
                      onPointerEnter={onPointerEnter}
                      onPointerLeave={onPointerLeave}
                      onPointerMove={onPointerMove}
                      size={20}
                      initialValue={item?.averageRating}
                      readonly
                      emptyColor="#000000"
                      // fillColor="red" // Filled star color
                      // fillColorArray={[
                      //   "#000000",
                      //   "#000000",
                      //   "#000000",
                      //   "#000000",
                      //   "#000000",
                      // ]} // Unfilled star color set to black
                    />
                  </div>
                  {item?.videodetail?.plan === "GOLD" ? <Premium /> : ""}
                  <div className="text w-100">{item?.videodetail?.title}</div>
                  <div className="btn">
                    <Button onClick={() => handleButtonCLick(item)}>
                      Watch
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className="no-data-found">No Data Found</div>
        )}
      </div>
      <VideoModal
        videoUrl={video}
        size={"lg"}
        initialState={isOpen}
        setIsOpen={setIsOpen}
      />
    </>
  );
};

export default DetailCard;
