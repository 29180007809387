import React from "react";
import { Row, Col } from "react-bootstrap";
import Assets from "../utils/Assets";
import { Heart, StarVideo } from "../utils/Svg";
import { Rating } from "react-simple-star-rating";
import Comments from "../components/Comments";

const WatchDetail = () => {
  const listData = [
    { id: 1, video: Assets.secondVideo },
    { id: 2, video: Assets.secondVideo },
    { id: 3, video: Assets.secondVideo },
    { id: 4, video: Assets.secondVideo },
    { id: 11, video: Assets.secondVideo },
    { id: 12, video: Assets.secondVideo },
    { id: 13, video: Assets.secondVideo },
    { id: 14, video: Assets.secondVideo },
    { id: 21, video: Assets.secondVideo },
    { id: 23, video: Assets.secondVideo },
    { id: 23, video: Assets.secondVideo },
    { id: 24, video: Assets.secondVideo },
  ];

  const commentData = [
    {
      id: 1,
      image: Assets.comm1,
      name: "Henry, Arthur",
      time: "1 min ago",
      rate: 1,
      text: "At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident,",
    },
    {
      id: 2,
      image: Assets.comm2,
      name: "Cooper, Kristin",
      time: "1 min ago",
      rate: 3,
      text: "At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident,",
    },
    {
      id: 3,
      image: Assets.comm3,
      name: "Flores, Juanita",
      time: "1 min ago",
      rate: 4,
      text: "At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident,",
    },
    {
      id: 4,
      image: Assets.comm4,
      name: "Nguyen, Shane",
      time: "1 min ago",
      rate: 2,
      text: "At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident,",
    },
  ];
  return (
    <>
      <div className="watch-detail container mt-4">
        <Row>
          <Col xs={12} md={12} lg={8}>
            <div className="video-content">
              {/* <iframe
                                width="835"
                                height="525"
                                src={Assets.Video}
                                title="YouTube video player"
                                frameBorder="0"
                                allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                            ></iframe> */}
              <video
                class="video-fluid z-depth-1 video-data"
                loop
                controls
                muted
              >
                <source src={Assets.Video} type="video/mp4" />
              </video>
            </div>
            <div className="current-video-detail">
              <Row className="mt-4">
                <Col xs={12} md={12} lg={8}>
                  <h1>Love Never Dies In Hearts: Rap, HipHop Song</h1>
                  <p className="mt-2">
                    <span>Artist Name:</span>Carter Calify (Spoiler)
                  </p>
                </Col>
                <Col xs={12} md={12} lg={4} className="text-details">
                  <p>
                    <span>Published:</span>20 March 2024
                  </p>
                  <div className="d-flex rating mt-2">
                    <div>
                      <span>
                        <StarVideo />
                      </span>
                      <span className="rate-count">4.1 Rating</span>
                    </div>
                    <div className="vertical-line-video"></div>
                    <div>
                      <span className="heart">
                        <Heart />
                      </span>
                      <span className="like-count">Likes 256</span>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <div className="description mt-5">
                    <h1>Description</h1>
                    <p className="mt-3">
                      Sed ut perspiciatis unde omnis iste natus error sit
                      voluptatem accusantium doloremque laudantium, totam rem
                      aperiam, eaque ipsa quae ab illo inventore veritatis et
                      quasi architecto beatae vitae dicta sunt explicabo. Nemo
                      enim ipsam voluptatem quia voluptas sit aspernatur aut
                      odit aut fugit.
                    </p>
                  </div>
                  <div className="line-podcast w-100 mt-4"></div>
                </Col>
              </Row>
            </div>
          </Col>
          <Col xs={12} md={12} lg={4}>
            <div className="list-video">
              <div className="heading mb-3">More from this Artist</div>
              <div className="line-podcast"></div>
              <div className="content mt-3">
                <div className="list-content">
                  <ul>
                    {listData?.map((dt) => (
                      <li key={dt?.id} className="mb-2">
                        {/* <iframe
                                                    width="100%"
                                                    height="525"
                                                    src={dt?.video}
                                                    title="YouTube video player"
                                                    frameBorder="0"
                                                    allowFullScreen
                                                    autoplay={false}
                                                ></iframe> */}
                        <video
                          class="video-fluid z-depth-1 video-data"
                          loop
                          controls
                          muted
                        >
                          <source src={dt?.video} type="video/mp4" />
                        </video>

                        <div className="list-detail">
                          <h2>Announcements</h2>
                          <h3>
                            At vero eos et accusamus et iusto odio dignissi mos
                            ducimus
                          </h3>
                          <p>2 hours ago</p>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </Col>
        </Row>

        <Row>
          <Col xs={12} md={12} lg={8}>
            <div className="reviews mt-5 mb-3">
              <h1>Reviews(23)</h1>
              {commentData?.map((dt) => (
                <Comments
                  image={dt?.image}
                  name={dt?.name}
                  text={dt?.text}
                  time={dt?.time}
                  rate={dt?.rate}
                />
              ))}
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};
export default WatchDetail;
