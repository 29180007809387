import React from "react";
import { Rating } from "react-simple-star-rating";

const Comments = ({ image, name, text, time, rate }) => {
  return (
    <>
      <div className="comments mt-5">
        <div className="user-details">
          <div className="image">
            <img src={image} />
          </div>
          <div className="name-detail">
            <p className="name mt-2">{name}</p>
            <Rating size={20} initialValue={rate} />
          </div>
        </div>
        <div className="user-comment mt-2">
          <h3>{text}</h3>
          <p className="time mt-2">{time}</p>
        </div>
      </div>
    </>
  );
};

export default Comments;
