import React, { useState } from "react";
import CarouselFadeComponent from "../components/Dashboard/BannarSlider";
import DetailCard from "../components/DetailCard";
import Assets from "../utils/Assets";
import { useSelector } from "react-redux";
import { useGetVideoContentBycatQuery } from "../Redux/Slices/Videos";

const ExclusivePage = () => {
  const [firstRenderData, setFirstRenderData] = useState(12);

  const search = useSelector((state) => state.search.search);

  const { isLoading, isFetching, data, refetch } = useGetVideoContentBycatQuery(
    {
      cat: "MUSICVIDEOS",
      params: {
        page: 1,
        limit: 20,
        ...(search && { search: search }),
      },
    },
    {
      refetchOnMountOrArgChange: true,
      refetchOnFocus: true,
      refetchOnReconnect: true,
    }
  );

  const defaultData = data?.data?.data?.slice(0, firstRenderData);

  return (
    <>
      <div className="type-details container mt-4">
        <div className="top-heading">
          <h1 className="heading">Featured</h1>
          {firstRenderData === data?.length ? (
            <p
              className="ms-auto cursor see-all"
              onClick={() => setFirstRenderData(12)}
            >
              Show less
            </p>
          ) : (
            <p
              className="ms-auto cursor see-all"
              onClick={() => setFirstRenderData(data.length)}
            >
              See All
            </p>
          )}
        </div>
        <DetailCard data={defaultData} />
      </div>
    </>
  );
};
export default ExclusivePage;
