import React from "react";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import AuthLayout from "../Layouts/AuthLayout/AuthLayout";
import SignUp from "../components/SignUp";
import SignIn from "../components/SignIn";
import ForgotPassword from "../components/ForgotPassword";
import ChangePassword from "../components/ChangePassword";
import Home from "../pages/Home";
import DashBoardMenu from "../Layouts/AuthLayout/DashBoard";
import DashBoardPage from "../pages/DashBoard";
import PodcastPage from "../pages/Podcast";
import MusicPage from "../pages/Music";
import ExclusivePage from "../pages/Exclusive";
import SettingPage from "../pages/Setting";
import HelpAndSupport from "../pages/HelpAndSupport";
import Features from "../components/Home/Features";
import TopNavBanner from "../components/Static/TopNavBAr";
import Plans from "../pages/Plans";
import WatchDetail from "../pages/WatchDetail";
import PublicdRoute from "./PublicdRoute";
import ProtectedRoute from "./ProtectedRoute";
import VerifyOtp from "../components/OTP";

const Navigation = () => {
  return (
    <>
      <Routes>
        {/* PUBLIC ROUTES AUTH */}
        <Route path="/" element={<Home />}></Route>
        <Route element={<PublicdRoute />}>
          <Route
            path="/sign-up"
            element={<AuthLayout children={<SignUp />} />}
          ></Route>
          <Route
            path="/sign-in"
            element={<AuthLayout children={<SignIn />} />}
          ></Route>
          <Route
            path="/forgot-password"
            element={<AuthLayout children={<ForgotPassword />} />}
          ></Route>
          <Route
            path="/verify-Otp"
            element={<AuthLayout children={<VerifyOtp />} />}
          ></Route>
          <Route
            path="/change-password"
            element={<AuthLayout children={<ChangePassword />} />}
          ></Route>
        </Route>

        {/* Private Route */}
        <Route element={<ProtectedRoute />}>
          <Route
            path="/dashboard"
            element={<DashBoardMenu children={<DashBoardPage />} />}
          ></Route>
          <Route
            path="/podcast"
            element={<DashBoardMenu children={<PodcastPage />} />}
          ></Route>
          <Route
            path="/music"
            element={<DashBoardMenu children={<MusicPage />} />}
          ></Route>
          <Route
            path="/exclusive"
            element={<DashBoardMenu children={<ExclusivePage />} />}
          ></Route>
          <Route
            path="/setting"
            element={<DashBoardMenu children={<SettingPage />} />}
          ></Route>
          <Route
            path="/watch-detail"
            element={<DashBoardMenu children={<WatchDetail />} />}
          ></Route>
        </Route>
        <Route
          path="/help-support"
          element={<DashBoardMenu children={<HelpAndSupport />} />}
        ></Route>
        <Route path="/plans" element={<Plans />}></Route>
      </Routes>
    </>
  );
};

export default Navigation;
