import React from "react";
import { Row, Col, Button } from 'react-bootstrap'
import Assets from "../../utils/Assets";
import Container from 'react-bootstrap/Container';
import { NavLink } from "react-router-dom/dist";

const TopBanner = () => {
    return (
        <>
            <div className="top-banner container">
                <Row>
                    <Col className="head-data">
                        <p className="step">Step Into Greatness
                            <br /> Your
                            <b> Premier Destination</b></p>
                        <p className="hip-hop">For Hip Hop & Rap Brilliance!</p>
                        <p className="lorem mt-2">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation.</p>
                        <div className="auth-btn mt-5">
                            <NavLink to={'/sign-in'}>
                                <Button>Login</Button>
                            </NavLink>
                            <NavLink to={'/sign-up'}>
                                <Button className="btn-two">Free Trial</Button>
                            </NavLink>

                        </div>
                    </Col>
                    <Col className='ban-img d-none d-lg-block'>
                        <div className="image-fluid">
                            <img src={Assets.BannerAddImage} />
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    )
}

export default TopBanner