const formatDate = (input) => {
  const date = new Date(input);

  const day = date.getDate().toString().padStart(2, "0");
  const month = date.toLocaleString("default", { month: "short" });
  const year = date.getFullYear();

  return `${day} ${month} ${year}`;
};

function formatNumber(num) {
  if (num < 1000) {
    return num.toString();
  } else if (num < 10000) {
    return (num / 1000).toFixed(1).replace(/\.0$/, "") + "k";
  } else if (num < 100000) {
    return Math.floor(num / 1000) + "k";
  } else {
    return num / 1000 + "k";
  }
}

export { formatDate, formatNumber };
