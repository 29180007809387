import React, { useState } from "react";
import Assets from "../utils/Assets";
import { Controller, useForm } from "react-hook-form";
import { Button, Form } from "react-bootstrap";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { NavLink, useNavigate } from "react-router-dom/dist";

import { VALIDATIONS, VALIDATIONS_TEXT } from "../utils/Validations";
import CustomInput from "./CustomInput";
import { GoBackIcon } from "../utils/Svg";
import { errorToast, success } from "./ToastComponent";
import { useSelector } from "react-redux";
import { useChangePasswordMutation } from "../Redux/Slices/Auth";

const ChangePassword = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const navigate = useNavigate();
  const {
    handleSubmit,
    register,
    control,
    reset,
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({ mode: "onChange" });
  const password = watch("password");
  const useremail = useSelector((state) => state.useremail.useremail);
  const [changepassword, { isLoading, isSuccess, isError, error }] =
    useChangePasswordMutation();

  const handleChangePassSubmit = async (data) => {
    let payload = { ...data, email: useremail };
    try {
      const result = await changepassword(payload).unwrap();
      success(result.message);
      navigate("/sign-in");
    } catch (e) {
      errorToast(e.data?.message);
    }
  };
  const handleBack = () => {
    navigate("/forgot-password");
  };

  return (
    <>
      <div className="signin-component">
        <div className="logo mb-3">
          <img src={Assets.Logo} />
        </div>
        <div onClick={handleBack} className="cursor go-back d-flex mt-5 mb-2">
          <GoBackIcon />
        </div>

        <div className="mt-1 mb-1 change-pass-tag-line">
          <h1>Set New Password</h1>
          <h5>Please enter your new password</h5>
        </div>

        <div className="sign-in-form">
          <Form onSubmit={handleSubmit(handleChangePassSubmit)}>
            <div className="mt-3">
              <Controller
                control={control}
                name="password"
                rules={{
                  required: {
                    value: true,
                    message: VALIDATIONS_TEXT.PASSWORD_REQUIRED,
                  },
                  minLength: {
                    value: VALIDATIONS.PASSWORD_MIN,
                    message: VALIDATIONS_TEXT.PASSWORD_MIN,
                  },
                  maxLength: {
                    value: VALIDATIONS.PASSWORD_MAX,
                    message: VALIDATIONS_TEXT.PASSWORD_MAX,
                  },
                }}
                render={({ field }) => (
                  <div className="password-input">
                    <CustomInput
                      {...field}
                      label="Password"
                      placeholder="Password"
                      className="name"
                      type={showPassword ? "text" : "password"}
                      required={true}
                      minLength={VALIDATIONS.PASSWORD_MIN}
                      maxLength={VALIDATIONS.PASSWORD_MAX}
                      value={field.value}
                      onChange={field.onChange}
                    />
                    <span
                      className="eye-icon"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? (
                        <AiOutlineEye />
                      ) : (
                        <AiOutlineEyeInvisible />
                      )}
                    </span>
                  </div>
                )}
              />
              {errors.password && (
                <small className="text-start d-block text-danger">
                  {errors.password.message}
                </small>
              )}
            </div>

            <div className="mt-3">
              <Controller
                control={control}
                name="confirmPassword"
                rules={{
                  required: {
                    value: true,
                    message: VALIDATIONS_TEXT.CONFIRM_PASSWORD_REQUIRED,
                  },
                  minLength: {
                    value: VALIDATIONS.PASSWORD_MIN,
                    message: VALIDATIONS_TEXT.PASSWORD_MIN,
                  },
                  maxLength: {
                    value: VALIDATIONS.PASSWORD_MAX,
                    message: VALIDATIONS_TEXT.PASSWORD_MAX,
                  },
                  validate: (value) =>
                    value === password || "The Passwords do not match",
                }}
                render={({ field }) => (
                  <div className="password-input">
                    <CustomInput
                      {...field}
                      label="Confirm Password"
                      placeholder="Confirm Password"
                      className="name"
                      type={showConfirmPassword ? "text" : "password"}
                      required={true}
                      minLength={VALIDATIONS.PASSWORD_MIN}
                      maxLength={VALIDATIONS.PASSWORD_MAX}
                      value={field.value}
                      onChange={field.onChange}
                    />
                    <span
                      className="eye-icon"
                      onClick={() =>
                        setShowConfirmPassword(!showConfirmPassword)
                      }
                    >
                      {showConfirmPassword ? (
                        <AiOutlineEye />
                      ) : (
                        <AiOutlineEyeInvisible />
                      )}
                    </span>
                  </div>
                )}
              />
              {errors.confirmPassword && (
                <small className="text-start d-block text-danger">
                  {errors.confirmPassword.message}
                </small>
              )}
            </div>

            <div className="btn-sumit">
              <Button
                className="btn-solid btn-blue mt-30"
                type="submit"
                disabled={isLoading}
              >
                Continue
              </Button>
            </div>
          </Form>
        </div>
        <div className="points">
          <ul>
            <li>At least 12 characters long but 14 or more is better.</li>
            <li>
              A combination of uppercase letters, lowercase letters, numbers,
              and symbols.
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};
export default ChangePassword;
