import React from "react";
import { Button, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { success } from "../ToastComponent";

const ThemeModal = ({
  btnText,
  cancelText,
  heading,
  Text,
  initialState,
  setState,
  deletetext,
  onSubmitModal,
  isDeleting,
  successmesg,
  size,
}) => {
  const navigate = useNavigate();

  return (
    <Modal
      className="theme-popup"
      show={initialState}
      onHide={() => {
        setState(false);
      }}
      size={size}
      centered
      aria-labelledby="contained-modal-title-vcenter"
    >
      {/* <Modal.Header closeButton></Modal.Header> */}
      <Modal.Body>
        <div>
          <div className="header">
            <h2>{heading}</h2>
            <p className="mt-2">{Text}</p>
          </div>

          <div className="mt-30 d-flex align-items-center justify-content-center ">
            {cancelText ? (
              <Button
                className="btn-outline btn-cancel"
                onClick={() => {
                  setState(false);
                }}
              >
                <div className="circle"></div>
                {cancelText}
              </Button>
            ) : (
              ""
            )}
            {btnText ? (
              <Button
                onClick={onSubmitModal}
                className="btn-solid btn-okay ms-4"
                disabled={isDeleting}
              >
                {btnText}
              </Button>
            ) : (
              ""
            )}
            {deletetext ? (
              <Button
                onClick={onSubmitModal}
                className="btn-solid btn-okay ms-4"
                disabled={isDeleting}
                style={{
                  backgroundColor: "red",
                }}
              >
                {deletetext}
              </Button>
            ) : (
              ""
            )}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ThemeModal;
