import React, { Suspense } from "react";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import Navigation from "./Routes/Navigations";

function App() {
  function BigSpinner() {
    return <h2>🌀 Loading...</h2>;
  }
  return (
    <div className="App">
      <BrowserRouter>
        <ToastContainer />
        <Navigation />
      </BrowserRouter>
    </div>
  );
}

export default App;
