import { DashboardIcon } from "./Svg";
import React, { lazy, Suspense } from "react";

import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

const LazyImage = ({ src, alt, className, style }) => (
  <LazyLoadImage
    className={className}
    style={style}
    alt={alt}
    effect="blur"
    src={src}
    height="100%"
    width="100%"
  />
);

export default LazyImage;
