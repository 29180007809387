import React from 'react';

const SliderComponent = () => {
    const dummyData = [
        { id: 1, name: 'Podcast' },
        { id: 2, name: 'Music Video' },
        { id: 3, name: 'Bite Sized Clips' },
        { id: 4, name: 'Vlogging' },
        { id: 5, name: 'Reality Shows' },
        { id: 6, name: 'Podcast' },
        { id: 7, name: 'Music Video' },
        { id: 8, name: 'Reality Shows' },
        { id: 9, name: 'Bite Sized Clips' },
        { id: 10, name: 'Bite Sized Clips' },
        { id: 11, name: 'Bite Sized Clips' },
        { id: 12, name: 'Bite Sized Clips' },
        { id: 13, name: 'Bite Sized Clips' },
        { id: 14, name: 'Bite Sized Clips' },
    ]
    return (
        <>
            <div className='list-comp'>
                <ul className='d-flex'>
                    {dummyData?.map((dt) => {
                        return (
                            <li key={dt?.id}>{dt?.name}</li>
                        )
                    })}
                </ul>
            </div>
        </>
    )
}

export default SliderComponent