import React, { useState } from "react";
import Assets from "../utils/Assets";
import { Controller, useForm } from "react-hook-form";
import { Button, Form } from "react-bootstrap";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";

import { VALIDATIONS, VALIDATIONS_TEXT } from "../utils/Validations";
import CustomInput from "./CustomInput";
import CustomCheck from "./CheckBox";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { errorToast, success } from "./ToastComponent";
import { useSignupMutation } from "../Redux/Slices/Auth";
import { setUserEmail } from "../Redux/Slices/UserSlice";
import { useDispatch } from "react-redux";
import { setLocationPath } from "../Redux/Slices/dashBoardSlice";

const SignUp = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const dispatch = useDispatch();
  const Location = useLocation();
  const [signup, { isLoading, isSuccess, isError, error }] =
    useSignupMutation();

  const navigate = useNavigate();
  const {
    handleSubmit,
    register,
    control,
    reset,
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({ mode: "onChange" });
  const password = watch("password");

  const handleSignInSubmit = async (data) => {
    delete data["is_agreed"];
    let payload = {
      deviceType: "web",
      deviceToken: "abc",
      role: "USER",
      isAgreed: 1,
    };
    let storeData = { ...data, ...payload };
    try {
      let result = await signup(storeData).unwrap();
      navigate("/verify-Otp");
      success(result.message);
      dispatch(setUserEmail(result?.data?.email));
      dispatch(setLocationPath(Location?.pathname));
    } catch (e) {
      errorToast(e.data?.message);
    }
  };
  return (
    <>
      <div className="signin-component">
        <div className="logo mb-4">
          <img src={Assets.Logo} />
        </div>
        <div className="mb-1 mt-4 w-100">
          <h5>Hello There,</h5>
          <h1>Register Yourself</h1>
        </div>
        <div className="sign-in-form">
          <Form onSubmit={handleSubmit(handleSignInSubmit)}>
            <div className="mt-3">
              <Controller
                control={control}
                name="firstName"
                rules={{
                  required: {
                    value: true,
                    message: VALIDATIONS_TEXT.NAME_REQUIRED,
                  },
                  maxLength: {
                    value: VALIDATIONS.NAME_MAX,
                    message: VALIDATIONS_TEXT.EMAIL,
                  },
                }}
                render={({ field }) => (
                  <CustomInput
                    {...field}
                    label="First Name"
                    placeholder="First Name"
                    className="name"
                    type="text"
                    required={true}
                    maxLength={VALIDATIONS.NAME_MAX}
                    value={field.value}
                    onChange={field.onChange}
                  />
                )}
              />
              {errors.firstName && (
                <small className="text-start d-block text-danger">
                  {errors.firstName.message}
                </small>
              )}
            </div>
            <div className="mt-3">
              <Controller
                control={control}
                name="lastName"
                rules={{
                  required: {
                    value: true,
                    message: VALIDATIONS_TEXT.NAME_REQUIRED,
                  },
                  maxLength: {
                    value: VALIDATIONS.NAME_MAX,
                    message: VALIDATIONS_TEXT.EMAIL,
                  },
                }}
                render={({ field }) => (
                  <CustomInput
                    {...field}
                    label="Last Name"
                    placeholder="Last Name"
                    className="name"
                    type="text"
                    required={true}
                    maxLength={VALIDATIONS.NAME_MAX}
                    value={field.value}
                    onChange={field.onChange}
                  />
                )}
              />
              {errors.lastName && (
                <small className="text-start d-block text-danger">
                  {errors.lastName.message}
                </small>
              )}
            </div>
            <div className="mt-3">
              <Controller
                control={control}
                name="email"
                rules={{
                  required: {
                    value: true,
                    message: VALIDATIONS_TEXT.EMAIL_REQUIRED,
                  },
                  maxLength: {
                    value: VALIDATIONS.EMAIL,
                    message: VALIDATIONS_TEXT.EMAIL,
                  },
                  pattern: {
                    value: /\S+@\S+\.\S+/,
                    message: VALIDATIONS_TEXT.EMAIL_FORMAT,
                  },
                }}
                render={({ field }) => (
                  <CustomInput
                    {...field}
                    label="Email Address"
                    placeholder="Email Address"
                    type="email"
                    className="name"
                    required={true}
                    maxLength={VALIDATIONS.EMAIL}
                    value={field.value}
                    onChange={field.onChange}
                  />
                )}
              />
              {errors.email && (
                <small className="text-start d-block text-danger">
                  {errors.email.message}
                </small>
              )}
            </div>

            <div className="mt-3">
              <Controller
                control={control}
                name="password"
                rules={{
                  required: {
                    value: true,
                    message: VALIDATIONS_TEXT.PASSWORD_REQUIRED,
                  },
                  minLength: {
                    value: VALIDATIONS.PASSWORD_MIN,
                    message: VALIDATIONS_TEXT.PASSWORD_MIN,
                  },
                  maxLength: {
                    value: VALIDATIONS.PASSWORD_MAX,
                    message: VALIDATIONS_TEXT.PASSWORD_MAX,
                  },
                }}
                render={({ field }) => (
                  <div className="password-input">
                    <CustomInput
                      {...field}
                      label="Password"
                      placeholder="Password"
                      className="name"
                      type={showPassword ? "text" : "password"}
                      required={true}
                      minLength={VALIDATIONS.PASSWORD_MIN}
                      maxLength={VALIDATIONS.PASSWORD_MAX}
                      value={field.value}
                      onChange={field.onChange}
                    />
                    <span
                      className="eye-icon"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? (
                        <AiOutlineEye />
                      ) : (
                        <AiOutlineEyeInvisible />
                      )}
                    </span>
                  </div>
                )}
              />
              {errors.password && (
                <small className="text-start d-block text-danger">
                  {errors.password.message}
                </small>
              )}
            </div>

            <div className="mt-3">
              <Controller
                control={control}
                name="confirmPassword"
                rules={{
                  required: {
                    value: true,
                    message: VALIDATIONS_TEXT.CONFIRM_PASSWORD_REQUIRED,
                  },
                  minLength: {
                    value: VALIDATIONS.PASSWORD_MIN,
                    message: VALIDATIONS_TEXT.PASSWORD_MIN,
                  },
                  maxLength: {
                    value: VALIDATIONS.PASSWORD_MAX,
                    message: VALIDATIONS_TEXT.PASSWORD_MAX,
                  },
                  validate: (value) =>
                    value === password || "The Passwords do not match",
                }}
                render={({ field }) => (
                  <div className="password-input">
                    <CustomInput
                      {...field}
                      label="Confirm Password"
                      placeholder="Confirm Password"
                      className="name"
                      type={showConfirmPassword ? "text" : "password"}
                      required={true}
                      minLength={VALIDATIONS.PASSWORD_MIN}
                      maxLength={VALIDATIONS.PASSWORD_MAX}
                      value={field.value}
                      onChange={field.onChange}
                    />
                    <span
                      className="eye-icon"
                      onClick={() =>
                        setShowConfirmPassword(!showConfirmPassword)
                      }
                    >
                      {showConfirmPassword ? (
                        <AiOutlineEye />
                      ) : (
                        <AiOutlineEyeInvisible />
                      )}
                    </span>
                  </div>
                )}
              />
              {errors.confirmPassword && (
                <small className="text-start d-block text-danger">
                  {errors.confirmPassword.message}
                </small>
              )}
            </div>

            <div className="checkbox mt-4">
              <Controller
                control={control}
                name="is_agreed"
                rules={{
                  required: {
                    value: true,
                    message: VALIDATIONS_TEXT.REQUIRED,
                  },
                }}
                render={({ field }) => (
                  <CustomCheck
                    {...field}
                    name="agree"
                    label={
                      <>
                        Agree to Prime Time Tournaments Terms and Conditions &
                        Privacy Policy{" "}
                      </>
                    }
                    type="checkbox"
                    value={field.value}
                    onChange={field.onChange}
                  />
                )}
              />
              {errors.is_agreed && (
                <small className="text-start d-block text-danger">
                  {errors.is_agreed.message}
                </small>
              )}
            </div>
            <div className="btn-sumit">
              <Button
                className="btn-solid btn-blue mt-3"
                type="submit"
                disabled={isLoading}
              >
                REGISTER
              </Button>
            </div>
          </Form>
          <div className="mt-1 tag-bottom">
            <h3>
              Already a member? <NavLink to={"/sign-in"}>Login</NavLink>
            </h3>
          </div>
        </div>
      </div>
    </>
  );
};
export default SignUp;
