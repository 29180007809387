import React, { useEffect, useRef, useState } from "react";
import { Reels } from "@sayings/react-reels";
import "@sayings/react-reels/dist/index.css";
import Assets from "../utils/Assets";
import {
  Comment,
  CrossNoti,
  StarVideo,
  SubmitComment,
  VideoIcon,
} from "../utils/Svg";
import { CiPause1 } from "react-icons/ci";
import { CiVolumeHigh } from "react-icons/ci";
import { IoVolumeMute } from "react-icons/io5";
import { Rating } from "react-simple-star-rating";
import Comments from "../components/Comments";
import CustomInput from "../components/CustomInput";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { Reelsdata } from "../Data/DummyData";
import { constant } from "../utils/constants";
import { formatDate, formatNumber } from "../utils/Helper";
import {
  useGetVideoContentBycatQuery,
  useLikeVideoMutation,
  useRateVideoMutation,
} from "../Redux/Slices/Videos";
import { errorToast, success } from "../components/ToastComponent";
import { FaHeart } from "react-icons/fa";
import { Controller, useForm } from "react-hook-form";
import { VALIDATIONS, VALIDATIONS_TEXT } from "../utils/Validations";
import { Button, Form } from "react-bootstrap";
import {
  useCommentVideoMutation,
  useGetCommentByVideoIdQuery,
} from "../Redux/Slices/comments";

const ReelsComponent = ({ data, isLoading, isFetching }) => {
  console.log(data, "Reel Dazata");
  const reelsData = Array.isArray(Assets.reel) ? Assets.reel : [];
  const videoRefs = useRef({});
  const [isPlaying, setIsPlaying] = useState(true);
  const [isMuted, setIsMuted] = useState(true);
  const [show, setShow] = useState(false);
  const [liked, setLiked] = useState(false);
  const [selectedDataId, setSelectedDataId] = useState(null);
  const fetchAllContentsByCat = useGetVideoContentBycatQuery();
  const [currentCommentId, setCurrentCommentId] = useState(null);
  const [playingVideoId, setPlayingVideoId] = useState(null);
  const [mutedVideoId, setMutedVideoId] = useState(null);
  const [
    commentvideo,
    {
      isLoading: createCommentLoading,
      isSuccess: createCommentIsSuccess,
      isError: createCommentIsError,
      error: createCommentError,
    },
  ] = useCommentVideoMutation();
  const {
    data: CommentData,
    error,
    isLoading: getCommentLoading,
    isFetching: getIsFetching,
  } = useGetCommentByVideoIdQuery(selectedDataId);
  console.log(CommentData?.data?.count, "CommentData?.data");
  const {
    handleSubmit,
    register,
    control,
    reset,
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({ mode: "onChange" });

  const [
    rateVideo,
    {
      isLoading: isRateVideoLoading,
      isSuccess: isRateVideoSuccess,
      isError: isRateVideoError,
      error: rateVideoError,
    },
  ] = useRateVideoMutation();
  const [
    likeVideo,
    {
      isLoading: isLikeVideoLoading,
      isSuccess: isLikeVideoSuccess,
      isError: isLikeVideoError,
      error: LikeVideoError,
    },
  ] = useLikeVideoMutation();

  const onSubmitRating = async (rate, dt) => {
    let payLoad = {
      videoId: dt?.id,
      rating: rate,
    };
    try {
      await rateVideo(payLoad).unwrap();
      // due to refecthing in real time and it will sort it so close this line
      // fetchAllContentsByCat.refetch();
    } catch (e) {
      errorToast(e.data?.message);
    }
  };
  const onSubmitLike = async (dt) => {
    console.log(dt, "like checking");
    let payLoad = {
      videoId: dt?.id,
      like: dt?.isLiked === null || dt?.isLiked === 0 ? 1 : 0,
    };
    try {
      await likeVideo(payLoad).unwrap();
      fetchAllContentsByCat.refetch();
    } catch (e) {
      errorToast(e.data?.message);
    }
  };

  const handleCommentSubmit = async (data) => {
    let payload = {
      videoId: selectedDataId,
      comment: data?.comment,
    };
    try {
      await commentvideo(payload).unwrap();
      reset();
    } catch (e) {
      errorToast(e.data?.message);
    }
  };

  const togglePlayPause = (id) => {
    if (playingVideoId === id) {
      videoRefs.current[id].pause();
      setPlayingVideoId(null);
    } else {
      if (playingVideoId !== null) {
        videoRefs.current[playingVideoId].pause();
      }
      videoRefs.current[id].play();
      setPlayingVideoId(id);
    }
  };

  const toggleVolume = (id) => {
    if (mutedVideoId === id) {
      videoRefs.current[id].muted = false;
      setMutedVideoId(null);
    } else {
      videoRefs.current[id].muted = true;
      setMutedVideoId(id);
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      if (playingVideoId !== null) {
        videoRefs.current[playingVideoId].muted = true;
        setMutedVideoId(playingVideoId);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [playingVideoId]);
  return (
    <>
      {data?.length > 0
        ? data.map((dt) => (
            <div
              className="show-reels d-lg-flex d-block"
              key={dt.id}
              style={{
                alignItems: show ? "" : "flex-end",
              }}
            >
              <div className="video-container">
                <video
                  className="video-element"
                  ref={(el) => (videoRefs.current[dt.id] = el)}
                  loop
                  muted={dt.id === mutedVideoId}
                  autoPlay={false}
                >
                  <source
                    src={`${constant.BASE_URL_IMAGE}${dt.shortvideo}`}
                    type="video/mp4"
                  />
                </video>
                <button
                  className="play-pause-button"
                  onClick={() => togglePlayPause(dt.id)}
                >
                  {playingVideoId === dt.id ? <CiPause1 /> : <VideoIcon />}
                </button>
                <button
                  className="volume-button"
                  onClick={() => toggleVolume(dt.id)}
                >
                  {dt.id === mutedVideoId ? <IoVolumeMute /> : <CiVolumeHigh />}
                </button>
                <div className="bottom-text">
                  <p>
                    <span>Published:</span> {formatDate(dt.createdAt)}
                  </p>
                  <p className="detail">{dt.videodetail?.title}</p>
                  <p>
                    <span>Artist Name:</span>
                    Tony
                  </p>
                </div>
              </div>

              {selectedDataId === dt.id ? (
                <div className="comments-sec">
                  <div className="reviews">
                    <div className="comment-header d-flex">
                      <h1>
                        Comment ({formatNumber(CommentData?.data?.count || 0)})
                      </h1>
                      <div
                        className="cursor ms-auto"
                        onClick={() => setSelectedDataId(null)}
                      >
                        <CrossNoti />
                      </div>
                    </div>
                    <div className="comments-list">
                      {CommentData?.data?.count === 0 ? (
                        <p className="no-data-found-comment">No Comments</p>
                      ) : (
                        CommentData?.data?.rows?.map((comment) => (
                          <Comments
                            key={comment.id}
                            image={`${constant.BASE_URL_IMAGE}uploads/${comment.user?.image}`}
                            name={`${comment.user?.firstName} ${comment.user?.lastName}`}
                            text={comment.comment}
                            time={formatDate(comment.createdAt)}
                            rate={0}
                          />
                        ))
                      )}
                    </div>
                  </div>
                  <div className="line-podcast mt-4 mb-2"></div>
                  <Form onSubmit={handleSubmit(handleCommentSubmit)}>
                    <div className="custom-component">
                      <Controller
                        control={control}
                        name="comment"
                        rules={{
                          required: {
                            value: true,
                            message: VALIDATIONS_TEXT.REQUIRED,
                          },
                        }}
                        render={({ field }) => (
                          <CustomInput
                            {...field}
                            label="Drop your comment here"
                            placeholder="Drop your comment here"
                            className="name"
                            type="text"
                            required={true}
                            maxLength={120}
                            value={field.value}
                            onChange={field.onChange}
                          />
                        )}
                      />
                      <div className="icon-submit d-flex">
                        <span className="vertical-line-video"></span>
                        <span className="">
                          <Button type="submit" className="default-btn">
                            <SubmitComment />
                          </Button>
                        </span>
                      </div>
                    </div>
                  </Form>
                </div>
              ) : (
                <div className="action-icons">
                  <div className="d-flex align-items-center">
                    <StarVideo />
                    <div>
                      <h1>{dt.averageRating} Rating</h1>
                    </div>
                  </div>
                  <Rating
                    initialValue={dt.userRating === null ? 0 : dt.userRating}
                    size={20}
                    onClick={(e) => onSubmitRating(e, dt)}
                  />
                  <div className="icon d-flex mt-3 mb-4">
                    <FaHeart
                      color={
                        dt.isLiked === null || dt.isLiked === 0
                          ? "white"
                          : "red"
                      }
                      size={20}
                      onClick={(e) => onSubmitLike(dt)}
                    />
                    <p>Likes {dt.like}</p>
                  </div>
                  <div
                    className="icon d-flex cursor mb-4"
                    onClick={() => setSelectedDataId(dt.id)}
                  >
                    <Comment />
                    <p>Comments {dt.comment}</p>
                  </div>
                </div>
              )}
            </div>
          ))
        : ""}
    </>
  );
};
export default ReelsComponent;
