// src/features/auth/authApiSlice.js
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { apiurl, constant } from "../../utils/constants";

export const commentApi = createApi({
  reducerPath: "commentApi",
  baseQuery: fetchBaseQuery({
    baseUrl: constant.BASE_URL_PROD,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().userdetail.userdetail?.token;
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ["Comment"],
  endpoints: (builder) => ({
    commentVideo: builder.mutation({
      query: (data) => ({
        url: `${apiurl.user}${apiurl.videocomment}`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Comment"],
    }),
    getCommentByVideoId: builder.query({
      query: (id) => {
        return {
          url: `${apiurl.user}${apiurl.getvideoscomments}/${id}`,
          method: "GET",
        };
      },
      providesTags: ["Comment"],
    }),
  }),
});

export const { useCommentVideoMutation, useGetCommentByVideoIdQuery } =
  commentApi;
