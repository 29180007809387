import React, { useState } from "react";
import CarouselFadeComponent from "../components/Dashboard/BannarSlider";
import DetailCard from "../components/DetailCard";

import Assets from "../utils/Assets";
import { useGetVideoContentBycatQuery } from "../Redux/Slices/Videos";
import { useSelector } from "react-redux";

const MusicPage = () => {
  const [featureRenderData, setFeatureRenderData] = useState(4);
  const [popularRenderData, setPopularRenderData] = useState(4);
  const [crazyRenderData, setCrazyRenderData] = useState(4);
  const search = useSelector((state) => state.search.search);

  const { isLoading, isFetching, data, refetch } = useGetVideoContentBycatQuery(
    {
      cat: "MUSICVIDEOS",
      params: {
        page: 1,
        limit: 20,
        ...(search && { search: search }),
      },
    },
    {
      refetchOnMountOrArgChange: true,
      refetchOnFocus: true,
      refetchOnReconnect: true,
    }
  );
  const defaultDataFeature = data?.data?.data?.slice(0, featureRenderData);
  const defaultDataPodcast = data?.data?.data?.slice(0, popularRenderData);
  const defaultDataCrazy = data?.data?.data?.slice(0, crazyRenderData);

  return (
    <>
      <div className="type-details container podcast mt-4">
        <div className="feature">
          <div className="top-heading">
            <h1 className="heading">Featured</h1>
            {featureRenderData === data?.length ? (
              <p
                className="ms-auto cursor see-all"
                onClick={() => setFeatureRenderData(4)}
              >
                Show less
              </p>
            ) : (
              <p
                className="ms-auto cursor see-all"
                onClick={() => setFeatureRenderData(data.length)}
              >
                See All
              </p>
            )}
          </div>

          <DetailCard data={defaultDataFeature} />
        </div>

        <div className="line-podcast"></div>
        <div className="popular-podcast mt-4">
          <div className="top-heading">
            <h1 className="heading">Popular Raps</h1>
            {popularRenderData === data?.length ? (
              <p
                className="ms-auto cursor see-all"
                onClick={() => setPopularRenderData(4)}
              >
                Show less
              </p>
            ) : (
              <p
                className="ms-auto cursor see-all"
                onClick={() => setPopularRenderData(data.length)}
              >
                See All
              </p>
            )}
          </div>

          <DetailCard data={defaultDataPodcast} />
        </div>

        <div className="line-podcast"></div>

        <div className="popular-podcast mt-4">
          <div className="top-heading">
            <h1 className="heading">Crazy Hip Hops</h1>
            {crazyRenderData === data?.length ? (
              <p
                className="ms-auto cursor see-all"
                onClick={() => setCrazyRenderData(4)}
              >
                Show less
              </p>
            ) : (
              <p
                className="ms-auto cursor see-all"
                onClick={() => setCrazyRenderData(data.length)}
              >
                See All
              </p>
            )}
          </div>

          <DetailCard data={defaultDataCrazy} />
        </div>
      </div>
    </>
  );
};

export default MusicPage;
